import {
    FormSectionHeader,
    FormSection,
    FormToggle,
} from "@cycleplatform/ui/components/forms";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import {
    FieldPath,
    FieldPathValue,
    FieldValues,
    useFormContext,
    useWatch,
} from "react-hook-form";
import { useCallback, useEffect, useState } from "react";

type ToggleFormSectionProps<TFieldValues extends FieldValues> = {
    field: FieldPath<TFieldValues>;
    disabled?: boolean;

    children: () => React.ReactNode;
    header?: React.ReactNode;
    defaultOpen?: boolean;
    logo?: string;
    onToggle?: (v: boolean) => void;
    help?: string;
    toggleDisabled?: boolean;
    toggleTooltip?: string;
    tooltip?: React.ReactNode;
    /** Optional custom check to decide whether this section is active or not */
    initialIsActive?: (value: unknown) => boolean;
    /** Optional function to call to unregister, otherwise calls form unregister  */
    unregisterFn?: (field: string) => void;
};

/**
 * Includes everything needed to enable/disable a full PanelSection within an RHF Form
 */
export function ToggleFormSection<TFieldValues extends FieldValues>({
    field,
    disabled,
    children,
    header = "Advanced",
    logo,
    help,
    initialIsActive = (value) => !!value,
    unregisterFn,
    toggleTooltip,
    tooltip,
    onToggle,
}: ToggleFormSectionProps<TFieldValues>) {
    const { getValues, setValue, unregister, control } = useFormContext();
    const fieldVal = useWatch({ control, name: field });

    const [isEnabled, setIsEnabled] = useState(initialIsActive(fieldVal));

    const [prevState, setPrevState] =
        useState<FieldPathValue<TFieldValues, typeof field>>();

    const toggle = useCallback(() => {
        setValue("_isDirty", true, { shouldDirty: true });

        if (isEnabled) {
            setPrevState(getValues(field));
            setIsEnabled(false);
            if (unregisterFn) {
                unregisterFn(field);
            } else {
                unregister(field, undefined);
            }
            return;
        }

        setIsEnabled(true);

        if (prevState) {
            setValue(field, prevState);
        }
    }, [isEnabled, setIsEnabled]);

    useEffect(() => {
        const isActive = initialIsActive(fieldVal);

        if (isActive !== isEnabled) {
            setIsEnabled(isActive);
        }
    }, [fieldVal]);
    return (
        <>
            <FormSectionHeader
                onClick={toggle}
                header={header}
                help={help}
                tooltip={tooltip}
            >
                <div className="flex grow items-center justify-end pl-4">
                    <div className="flex items-center gap-8">
                        {logo && <img src={logo} className="mx-2 w-40 py-2" />}
                        <Tooltip
                            message={toggleTooltip}
                            disabled={!toggleTooltip}
                        >
                            <FormToggle
                                disabled={disabled}
                                value={isEnabled}
                                onChange={(v) => {
                                    toggle();
                                    onToggle?.(v);
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
            </FormSectionHeader>

            {isEnabled && (
                <ExpandedContentWrapper>{children()}</ExpandedContentWrapper>
            )}
        </>
    );
}

function ExpandedContentWrapper({ children }: { children: React.ReactNode }) {
    if (children === <></>) {
        return null;
    }
    return <FormSection className="p-4 ">{children}</FormSection>;
}
