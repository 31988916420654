import {
    faServer,
    faUsbDrive,
    faPlug,
    faCloudArrowUp,
    faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    formatSFTPUrl,
    getSftpCommand,
    getVolumeType,
    isVolumeDeployed,
} from "~/modules/containers/util/volumes";
import {
    Environment,
    InstanceVolume,
    useGetServerQuery,
} from "~/services/cycle";
import { Meter } from "@cycleplatform/ui/components/progress";
import { formatBytesString } from "@cycleplatform/core/util/bytes";
import { Button, ButtonLink } from "@cycleplatform/ui/components/buttons";
import { CopyInput, FormField } from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import classNames from "classnames";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { UpdateVolumeSizePanel } from "./UpdateVolumeSizePanel";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    StyledCell,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";

type VolumeTableRowProps = {
    volume: InstanceVolume;
    environment: Environment | undefined;
    containerId: string | undefined;
    instanceId: string | undefined;
    /**
     * Used to override default destination name of volume.config.destination
     * Example - in VM Config
     */
    destinationName?: string;
};

export function VolumeTableRow({
    volume,
    environment,
    containerId,
    instanceId,
    destinationName,
}: VolumeTableRowProps) {
    const type = getVolumeType(volume);
    const isDeployed = isVolumeDeployed(volume);

    const { data: server, error } = useGetServerQuery(
        {
            serverId: volume.deployed.server_id || "",
        },
        { skip: !volume.deployed.server_id }
    );

    const hasSftpAccess = volume.config.remote_access?.enable;

    if (error) {
        throw error;
    }

    return (
        <StyledTableRow>
            <StyledCell className="w-1/3">
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon
                        className="text-cycle-blue pr-4 text-lg"
                        icon={NavIcons["volumes"]}
                    />
                    <span className="truncate w-60">{destinationName || volume.config.destination}</span>
                    {hasSftpAccess && (
                        <PositionedMenu
                            className="w-[40rem]"
                            render={() => (
                                <>
                                    {volume.deployed.server_id ? (
                                        <>
                                            <h3 className="mb-4 text-xl">
                                                <FontAwesomeIcon
                                                    icon={faUsbDrive}
                                                />{" "}
                                                SFTP Access -{" "}
                                                {volume.config.destination}
                                            </h3>
                                            {server?.data?.features.sftp ? (
                                                <>
                                                    <div className="flex gap-4">
                                                        <FormField label="command">
                                                            <CopyInput
                                                                value={getSftpCommand(
                                                                    volume.sftp
                                                                )}
                                                            />
                                                        </FormField>

                                                        <FormField
                                                            label={` Password [${volume.sftp.password.algorithm.toLocaleUpperCase()}]`}
                                                        >
                                                            <CopyInput
                                                                type={
                                                                    volume.sftp
                                                                        .password
                                                                        .algorithm ===
                                                                    "raw"
                                                                        ? "password"
                                                                        : undefined
                                                                }
                                                                value={
                                                                    volume.sftp
                                                                        .password
                                                                        .data
                                                                }
                                                            />
                                                        </FormField>
                                                    </div>

                                                    <FormField label="host">
                                                        <CopyInput
                                                            value={`${volume.sftp.host}:${volume.sftp.port}`}
                                                        />
                                                    </FormField>

                                                    <FormField label="username">
                                                        <CopyInput
                                                            value={
                                                                volume.sftp
                                                                    .username
                                                            }
                                                        />
                                                    </FormField>

                                                    <PanelFooter>
                                                        <ButtonLink
                                                            flavor="primary"
                                                            to={formatSFTPUrl(
                                                                volume
                                                            )}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faPlug}
                                                            />{" "}
                                                            Open in SFTP
                                                            Application
                                                        </ButtonLink>
                                                    </PanelFooter>
                                                </>
                                            ) : (
                                                <div className="items-center ">
                                                    <p>
                                                        This volume has remote
                                                        access enabled, but the
                                                        server it is deployed to
                                                        does not allow SFTP
                                                        access. Please enable
                                                        SFTP access on the
                                                        server in order to
                                                        continue.
                                                    </p>
                                                    <PanelFooter>
                                                        <ButtonLink
                                                            to={`/infrastructure/servers/${volume.deployed.server_id}/configure`}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faServer}
                                                            />{" "}
                                                            Server Settings
                                                        </ButtonLink>
                                                    </PanelFooter>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="items-center ">
                                            <p>
                                                This volume has been recently
                                                migrated and SFTP is not yet
                                                availiable
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                        >
                            <Button className="py-0 ml-4">
                                <FontAwesomeIcon icon={faCloudArrowUp} /> SFTP
                            </Button>
                        </PositionedMenu>
                    )}
                </div>
            </StyledCell>
            <StyledCell>{type?.type || "Unknown Type"}</StyledCell>
            <StyledCell className="w-1/3">
                <Meter
                    className="w-full"
                    text={`${formatBytesString(
                        volume.deployed.storage.used * 1024 * 1024,
                        1
                    )} / ${formatBytesString(
                        volume.deployed.storage.total * 1024 * 1024,
                        1
                    )}`}
                    // If zero, set the total such that the meter shows empty, not full
                    percent={
                        (volume.deployed.storage.used /
                            volume.deployed.storage.total || 0.000001) * 100
                    }
                />
                {!isDeployed ? (
                    <Tooltip
                        as="span"
                        message={"Volume usage stats sync every 10 min"}
                        className={classNames(
                            "text-warning-accent flex items-center text-xs"
                        )}
                    >
                        <FontAwesomeIcon icon={faExclamationCircle} />
                    </Tooltip>
                ) : null}
            </StyledCell>

            <StyledCell className="text-end">
                <UpdateVolumeSizePanel
                    volume={volume}
                    environment={environment}
                    containerId={containerId}
                    instanceId={instanceId}
                />
            </StyledCell>
        </StyledTableRow>
    );
}
