import classNames from "classnames";
import { DataLabel } from "./DataLabel";
import { skeletonStyles } from "../loaders/skeleton/skeletonStyle";

type LabeledDataTextProps = {
    children: React.ReactNode;
    label: string;
    className?: string;
    labelClassName?: string;
    isLoading?: boolean;
    block?: boolean;
    help?: string;
};

export function LabeledDataText({
    children,
    label,
    className,
    labelClassName,
    block,
    isLoading,
    help,
}: LabeledDataTextProps) {
    return (
        <div className={classNames(className, "flex gap-2")}>
            <DataLabel className={labelClassName} label={label} help={help} />
            <div
                className={classNames(
                    "flex-1",
                    isLoading && skeletonStyles,
                    !block && "truncate"
                )}
            >
                {children}
            </div>
        </div>
    );
}
