import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { MultiStepFormDialog } from "../../MultiStepFormDialog/MultiStepFormDialog";
import { MultiStepFormSteps } from "../../MultiStepFormDialog/components/MultStepFormSteps";
import { MultiStepFormStep } from "../../MultiStepFormDialog/components/MultiStepFormStep";
import { Sidebar } from "~/components/layout/sidebar";
import { Dialogs } from "../../Dialogs";
import { HubSetupPaymentStep } from "./steps/setupPayment/HubSetupPaymentStep";
import { HubSetupTierStep } from "./steps/setupTier/HubSetupTierStep";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { HubSetupInvitesStep } from "./steps/invites/HubSetupInvitesStep";
import { HubSetupReviewStep } from "./steps/review/HubSetupReviewStep";

export const HUB_SETUP_KEY = "hub-setup";

export function HubSetupDialog() {
    const hub = useAppSelector(selectActiveHub);

    return (
        <>
            <Sidebar />
            <MultiStepFormDialog
                title={`Setup Hub - ${hub?.name}`}
                isOpen={true}
                icon={faLock}
                isCloseable={false}
                onClose={() => null} // don't allow clicking on the bg to reset the form
                formKey={HUB_SETUP_KEY}
            >
                <MultiStepFormSteps>
                    <MultiStepFormStep name="Select Tier">
                        <HubSetupTierStep />
                    </MultiStepFormStep>
                    <MultiStepFormStep name="Payment Method">
                        <HubSetupPaymentStep />
                    </MultiStepFormStep>

                    <MultiStepFormStep name="Place Order">
                        <HubSetupReviewStep />
                    </MultiStepFormStep>

                    <MultiStepFormStep name="Invite Members">
                        <HubSetupInvitesStep />
                    </MultiStepFormStep>
                </MultiStepFormSteps>
            </MultiStepFormDialog>
            <Dialogs />
        </>
    );
}
