import {
    useGetAccountInvitesQuery,
    useGetAccountQuery,
} from "~/services/cycle";
import { Menu } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faGear,
    faPowerOff,
    faSync,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Avatar } from "@cycleplatform/ui/components/avatar";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { generateDialogLink } from "~/components/dialogs/helpers";

export function AccountMenu() {
    const {
        data: account,
        error: accountError,
        isFetching,
        refetch,
    } = useGetAccountQuery();
    const { data: invites, error: invitesError } = useGetAccountInvitesQuery({
        include: ["senders", "hubs"],
    });
    if (accountError) {
        throw accountError;
    }
    if (invitesError) {
        throw invitesError;
    }
    return (
        <Menu>
            <Menu.Button>
                <div className="flex cursor-pointer items-center gap-2 px-2 text-lg transition-colors">
                    {account?.data?.email.address && (
                        <Avatar
                            className="ring-cycle-blue-accent hover:ring-cycle-blue ring-2"
                            email={account.data.email.address}
                        />
                    )}
                </div>
            </Menu.Button>
            <Menu.Items className="dark:bg-cycle-black-accent absolute top-16 left-60 z-40 w-full max-w-[12rem] origin-center scale-100 transform divide-y rounded-md bg-white opacity-100 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                {accountError ? (
                    <div className="text-error flex flex-wrap gap-4 p-4">
                        <p>There was an error loading your account.</p>
                        <LoaderButton
                            isLoading={isFetching}
                            onClick={refetch}
                            icon={faSync}
                        >
                            Retry
                        </LoaderButton>
                    </div>
                ) : (
                    <>
                        <Menu.Item>
                            {({ active }) => (
                                <div className="dark:bg-cycle-black-accent border-b bg-white p-4 text-left transition">
                                    <Link
                                        to={generateDialogLink("account", {})}
                                        className={classNames(
                                            active && "text-cycle-blue"
                                        )}
                                    >
                                        <p className="text-cycle-gray dark:text-cycle-white mb-2 break-all text-lg">
                                            {account?.data?.name.first}{" "}
                                            {account?.data?.name.last}
                                        </p>
                                        <div className="flex justify-between ">
                                            <p className="text-xs">
                                                {account?.data?.email.address}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        "p-2 transition",
                                        active && "text-cycle-blue"
                                    )}
                                >
                                    <Link
                                        to={generateDialogLink("account", {
                                            "dialog-tab": "hubs",
                                        })}
                                    >
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    className="pr-2"
                                                />
                                                Invites
                                            </div>
                                            {invites?.data?.length ? (
                                                <div className="bg-cycle-blue relative h-6 w-6 rounded-full">
                                                    <span className="text-cycle-white absolute inset-0 text-center font-bold">
                                                        {invites?.data?.length}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        "p-2 transition",
                                        active && "text-cycle-blue"
                                    )}
                                >
                                    <Link
                                        to={generateDialogLink("account", {})}
                                    >
                                        <FontAwesomeIcon
                                            icon={faGear}
                                            className="pr-2"
                                        />
                                        Settings
                                    </Link>
                                </div>
                            )}
                        </Menu.Item>

                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        "p-2 transition",
                                        active && "text-cycle-blue"
                                    )}
                                >
                                    <Link to="/logout">
                                        <FontAwesomeIcon
                                            icon={faPowerOff}
                                            className="pr-2"
                                        />
                                        Log Out
                                    </Link>
                                </div>
                            )}
                        </Menu.Item>
                    </>
                )}
            </Menu.Items>
        </Menu>
    );
}
