import classNames from "classnames";
import { FC } from "react";
import { NavLink, To } from "react-router-dom";
import { BetaBadge } from "../../badges";

type HeaderTabProps = {
    title: string;
    to: To;
    end?: boolean;
    className?: string;
    beta?: boolean;
};

export const HeaderTab: FC<HeaderTabProps> = ({
    title,
    to,
    end,
    className,
    beta,
}) => {
    return (
        <NavLink
            key={title}
            to={to}
            end={end}
            className={({ isActive }) =>
                classNames(
                    className,
                    "py-3",
                    "text-cycle-gray dark:text-cycle-white hover:text-cycle-blue inline-block px-5 font-semibold ",
                    isActive
                        ? " border-cycle-blue !text-cycle-blue border-b-2"
                        : ""
                )
            }
        >
            {title}

            {beta ? <BetaBadge className="!ml-2" /> : null}
        </NavLink>
    );
};
