import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { clearDialogParams, isDialogOpen } from "~/components/dialogs/helpers";
import { HubCreateForm } from "~/components/hubs/forms/HubCreateForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function HubCreateDialog() {
    const navigate = useNavigate();

    return (
        <StyledFormDialog
            title={"Create New Hub"}
            isOpen={isDialogOpen("hub-create")}
            onClose={() => navigate(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <HubCreateForm isDialog />
            </DialogColumn>
        </StyledFormDialog>
    );
}
