import { faLock } from "@fortawesome/pro-solid-svg-icons";

import { MultiStepFormDialog } from "../../MultiStepFormDialog/MultiStepFormDialog";
import { MultiStepFormSteps } from "../../MultiStepFormDialog/components/MultStepFormSteps";
import { MultiStepFormStep } from "../../MultiStepFormDialog/components/MultiStepFormStep";
import { EnableStep } from "./steps/EnableStep";
import { VerifyStep } from "./steps/VerifyStep";
import { CompleteStep } from "./steps/CompleteStep";
import { Sidebar } from "~/components/layout/sidebar";
import { Dialogs } from "../../Dialogs";

export function Enable2FaDialog() {
    return (
        <>
            <Sidebar />
            <MultiStepFormDialog
                title="Two-Factor Auth Required"
                isOpen={true}
                icon={faLock}
                isCloseable={false}
                onClose={() => null} // don't allow clicking on the bg to reset the form
                formKey="enable-2fa"
            >
                <MultiStepFormSteps>
                    <MultiStepFormStep name="Enable 2FA">
                        <EnableStep />
                    </MultiStepFormStep>
                    <MultiStepFormStep name="Verify">
                        <VerifyStep />
                    </MultiStepFormStep>
                    <MultiStepFormStep name="Complete">
                        <CompleteStep />
                    </MultiStepFormStep>
                </MultiStepFormSteps>
            </MultiStepFormDialog>
            <Dialogs />
        </>
    );
}
