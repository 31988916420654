import { DefinitionsFromApi, OverrideResultType } from "@reduxjs/toolkit/query";
import {
    cycleApi,
    InstanceTelemetryResourceSnapshot,
    GetInstanceTelemetryStreamAuthApiResponse,
} from "../../__generated";
import { tagTypes } from "../tags";
import { $info, $warn, $error, $trace } from "@cycleplatform/core/util/log";
import { addSeconds, isAfter } from "date-fns";
import {
    PLATFORM_SOCKET_HEARTBEAT_MESSAGE,
    PLATFORM_SOCKET_HEARTBEAT_TIMEOUT,
} from "@cycleplatform/core/modules/websocket";
import { Draft } from "@reduxjs/toolkit";

export type InstanceTelemStreamData = {
    data: GetInstanceTelemetryStreamAuthApiResponse["data"] & {
        expires?: string;
        telemetry: InstanceTelemetryResourceSnapshot[];
    };
};

// https://github.com/reduxjs/redux-toolkit/pull/2953
type Definitions = DefinitionsFromApi<typeof cycleApi>;
type TagTypes = typeof tagTypes[number];

type GetInstanceResourcesTelemetryStreamDefinition = OverrideResultType<
    Definitions["getInstanceTelemetryStreamAuth"],
    InstanceTelemStreamData
>;

type UpdatedDefinitions = Omit<
    Definitions,
    "getInstanceTelemetryStreamAuth"
> & {
    getInstanceTelemetryStreamAuth: GetInstanceResourcesTelemetryStreamDefinition;
};

type MaybeDrafted<T> = T | Draft<T>;
export function applyEndpointTransforms() {
    const api = cycleApi.enhanceEndpoints<TagTypes, UpdatedDefinitions>({
        endpoints: {
            getHubs: {
                query: (queryArg) => ({
                    url: `/v1/hubs`,
                    params: {
                        page: queryArg.page || {
                            size: 100,
                            number: 1,
                        },
                        filter: queryArg.filter,
                    },
                }),
            },
            getServerTelemetry: {
                keepUnusedDataFor: 0,
            },
            getRole: {
                keepUnusedDataFor: 0,
            },

            getInstanceTelemetryStreamAuth: {
                keepUnusedDataFor: 0,
                providesTags: ["Instance.Telemetry"],
                // Reuse the cache from the auth request
                // to stream in telemetry and manage with same subscription
                transformResponse: (
                    response: GetInstanceTelemetryStreamAuthApiResponse
                ): InstanceTelemStreamData => {
                    return {
                        data: {
                            token: response.data?.token,
                            address: response.data?.address,
                            expires: addSeconds(new Date(), 60).toISOString(),
                            telemetry: [],
                        },
                    } as InstanceTelemStreamData;
                },
                async onCacheEntryAdded(
                    args,
                    {
                        dispatch,
                        cacheDataLoaded,
                        getCacheEntry,
                        cacheEntryRemoved,
                        updateCachedData,
                    }
                ) {
                    let ws: WebSocket | undefined = undefined;
                    try {
                        await cacheDataLoaded;

                        // Function for handling (re)connection of websocket
                        const connectWs = () => {
                            // If there is already a valid one, skip this.
                            if (ws && ws.readyState === ws.OPEN) {
                                // already open
                                return;
                            }

                            // Pull fresh token from cache. Tokens expire every 60 seconds
                            // so its very easy to attempt a reconnect with an expired token.
                            const { data } = getCacheEntry();
                            const credentials = data as InstanceTelemStreamData;

                            if (!credentials?.data) {
                                $warn(
                                    "no data in instance telemetry creds cache - not reconnecting"
                                );
                                return;
                            }

                            // need to grab newest token from cache
                            const address = credentials?.data?.address;
                            const token = credentials?.data?.token;
                            if (!token) {
                                $warn(
                                    "instance telemetry token not present in response"
                                );
                                dispatch(
                                    cycleApi.util.invalidateTags([
                                        "Instance.Telemetry",
                                    ])
                                );
                            }
                            return new WebSocket(`${address}?token=${token}`);
                        };

                        const connectListeners = () => {
                            ws?.addEventListener("open", openHandler);
                            ws?.addEventListener("close", closeHandler);
                            ws?.addEventListener("message", messageHandler);
                            ws?.addEventListener("error", errorHandler);
                        };

                        const disconnectListeners = () => {
                            ws?.removeEventListener("open", openHandler);
                            ws?.removeEventListener("message", messageHandler);
                            ws?.removeEventListener("close", closeHandler);
                            ws?.removeEventListener("error", errorHandler);
                        };

                        // Handles disconnect of websocket in a standardized way.
                        // Since each browser is very different, we've standardized ping/pongs on the platform
                        // so that if a client sends the string "heartbeat" to the platform, the platform responds with "heartbeat"
                        // down the pipe. Using this, we can manually check if a socket is no longer receiving data from the platform,
                        // and after a timeout force a reconnect.
                        // Some browsers (chrome/webkit) are buffering socket messages even when the user is completely offline and
                        // attempting to reconnect, sometimes without ever letting us know something happened. This alleviates that problem.
                        let hbTimeout: NodeJS.Timeout;
                        const makeHbTimeout = () => {
                            return setTimeout(() => {
                                $warn(
                                    `heartbeat timeout on telemetry socket for instance ${args.instanceId} - closing socket`
                                );

                                ws?.close();
                                closeHandler();
                            }, PLATFORM_SOCKET_HEARTBEAT_TIMEOUT);
                        };

                        let heartbeat: NodeJS.Timer;
                        const openHandler = () => {
                            $info(
                                `connected to instance ${args.instanceId} - telemetry`
                            );
                            // Now that we are connected, start heartbeat ping pong
                            heartbeat = setInterval(() => {
                                if (ws?.readyState === 1) {
                                    ws?.send(PLATFORM_SOCKET_HEARTBEAT_MESSAGE);
                                }
                            }, 15_000);
                            hbTimeout = makeHbTimeout();
                        };

                        let reconnectInterval: NodeJS.Timer | undefined =
                            undefined;
                        const closeHandler = () => {
                            $warn(
                                `disconnected from instance ${args.instanceId} - telemetry`
                            );

                            disconnectListeners();
                            clearInterval(reconnectInterval);
                            clearInterval(heartbeat);
                            clearTimeout(hbTimeout);
                            ws = undefined;

                            if (!getCacheEntry().data) {
                                $trace(
                                    `telemetry creds cache for instance ${args.instanceId} cleared - not attempting reconnect`
                                );
                                return;
                            }

                            dispatch(
                                cycleApi.util.invalidateTags([
                                    "Instance.Telemetry",
                                ])
                            );

                            // If for some reason just the websocket is disconnected,
                            // but not the internet (like the platform going down for an update?)
                            // then we need to internally handle the reconnect. I tried using listeners,
                            // a completely independent slice with thunks, and it ended up being much more complicated.
                            // Using the hook + RTK Query makes most of this simple, with the exception of this reconnect.
                            reconnectInterval = setInterval(() => {
                                const { data } = getCacheEntry();
                                const credentials =
                                    data as InstanceTelemStreamData;

                                // If we already have a new ws, do nothing.
                                if (ws && ws.readyState === 1) {
                                    clearInterval(reconnectInterval);
                                    return;
                                }
                                // if token is expired, refetch it before attempting a reconnect
                                if (
                                    credentials?.data?.expires &&
                                    isAfter(
                                        new Date(),
                                        new Date(credentials.data.expires)
                                    )
                                ) {
                                    $trace(
                                        `credentials for telemetry stream on instance ${args.instanceId} are expired - waiting for new creds before reconnecting`
                                    );
                                    return;
                                }

                                $warn(
                                    `reconnecting to telemetry stream on instance ${args.instanceId}`
                                );
                                clearInterval(reconnectInterval);
                                ws = connectWs();
                                connectListeners();
                            }, 2000);
                        };

                        const errorHandler = (ev: Event) => {
                            $error(
                                `socket error occurred: ${JSON.stringify(ev)}`
                            );
                        };

                        const messageHandler = (event: MessageEvent) => {
                            if (
                                typeof event.data === "string" &&
                                event.data === PLATFORM_SOCKET_HEARTBEAT_MESSAGE
                            ) {
                                clearTimeout(hbTimeout);
                                hbTimeout = makeHbTimeout();
                                return;
                            }

                            const snapshot: InstanceTelemetryResourceSnapshot =
                                JSON.parse(event.data);

                            updateCachedData((draft) => {
                                const { data } =
                                    draft as MaybeDrafted<InstanceTelemStreamData>;
                                return {
                                    data: {
                                        ...data,
                                        telemetry: [
                                            ...data.telemetry,
                                            snapshot,
                                        ],
                                    },
                                };
                            });
                        };

                        ws = connectWs();
                        connectListeners();

                        await cacheEntryRemoved;

                        if (ws) {
                            ws.close();
                        }

                        // For some reason, some browsers don't emit the onclose handler until a close is received
                        // from the server. We can force it here.
                        closeHandler();
                        // Since this is a purposeful disconnect, ensure we don't accidentally try to reconnect
                        clearInterval(reconnectInterval);
                        disconnectListeners();
                    } catch (e) {
                        ws?.close();
                    }
                },
            },
        },
    });

    const {
        useGetInstanceTelemetryStreamAuthQuery,
        // useInstanceConsoleAuthQuery,
    } = api;
    return {
        useGetInstanceTelemetryStreamAuthQuery,
        // useInstanceConsoleAuthQuery,
    };
}
