import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { NavIcons } from "~/components/layout/NavIcons";

export function ChooseAnotherHubButton() {
    return (
        <ButtonLink
            to={generateDialogLink("hub-select", {})}
            icon={NavIcons["hubs"]}
        >
            Choose Another Hub
        </ButtonLink>
    );
}
