import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import {
    PageBody,
    PageContent,
    PageHeader,
} from "@cycleplatform/ui/components/page";
import {
    CreateOrderApiArg,
    Hub,
    useGetBillingServicesQuery,
} from "~/services/cycle";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { NavIcons } from "~/components/layout/NavIcons";
import { ResourceLayout } from "~/components/layout/resources/ResourceLayout";
import { rhfConfig } from "~/components/forms/util";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { useKeepFormCurrent } from "~/components/common/forms";
import { generateDialogLink } from "~/components/dialogs/helpers";
import {
    PLAN_TILE_CLASSNAME,
    PlanTier,
} from "@cycleplatform/ui/components/resources/plans/PlanTier";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { AccessControlOverlay } from "~/components/common/buttons";
import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { HubUsageContent } from "~/routes/billing/index/hubUsage/HubUsageContent";
import { CreditsContent } from "~/routes/billing/index/CreditsContent";
import { InvoiceHistoryContent } from "~/routes/billing/index/invoiceHistory/InvoiceHistoryContent";
import { InvoicesListContent } from "~/routes/billing/index/InvoicesListContent";
import {
    CREDIT_CARD_FEE_LIMIT_MILLS,
    CREDIT_CARD_FEE_PERCENTAGE,
    CreditCardFeeWarning,
} from "~/components/hubs/CreditCardFeeWarning";
import { useGetCurrentPlanTier } from "~/components/hubs/hooks";
import { Link } from "react-router-dom";
import { millsToDollars } from "@cycleplatform/core/util/money";

const getFormDefaults = (hub?: Hub) => ({
    tier_plan_id: hub?.billing?.plans?.tier_id || undefined,
});

export default function SettingsPlans() {
    return (
        <ResourceLayout>
            <PageHeader title="Plans" icon={NavIcons["hubPlans"]}>
                <AccessControlOverlay capability={"billing-services-manage"}>
                    <ButtonLink
                        className="whitespace-nowrap "
                        to={generateDialogLink("change-hub-plan", {
                            "dialog-step": "0",
                        })}
                        icon={faEdit}
                        flavor="primary"
                    >
                        Change Hub Plans
                    </ButtonLink>
                </AccessControlOverlay>
            </PageHeader>
            <PageBody>
                <PageContent>
                    <div className={classNames(PLAN_TILE_CLASSNAME, "flex-1")}>
                        <Panel>
                            <PanelTitle title="Current Plan" />
                            <PanelContentBoundary
                                capability={"billing-services-view"}
                            >
                                <CurrentPlan />

                                <CreditCardFeeWarning
                                    message={
                                        <>
                                            Invoices that exceed $
                                            {millsToDollars(
                                                CREDIT_CARD_FEE_LIMIT_MILLS
                                            )}
                                            /month incur a
                                            {CREDIT_CARD_FEE_PERCENTAGE}%
                                            processing fee when paying with a
                                            credit card.{" "}
                                            <Link to="/billing/methods">
                                                Update your primary payment
                                                method
                                            </Link>{" "}
                                            to a bank account to avoid this fee.
                                        </>
                                    }
                                />
                            </PanelContentBoundary>
                        </Panel>
                    </div>

                    <Panel className="flex-1 ">
                        <PanelTitle title="Hub Usage" />
                        <PanelContentBoundary
                            capability={["servers-view", "hubs-members-view"]}
                        >
                            <HubUsageContent />
                        </PanelContentBoundary>
                    </Panel>
                    <div className="flex-1">
                        <Panel>
                            <PanelTitle title="invoice history" />
                            <PanelContentBoundary
                                capability={"billing-invoices-view"}
                            >
                                <InvoiceHistoryContent />
                            </PanelContentBoundary>
                        </Panel>

                        <Panel>
                            <PanelTitle title="recent invoices" />
                            <PanelContentBoundary
                                capability={"billing-invoices-view"}
                            >
                                <InvoicesListContent />
                            </PanelContentBoundary>
                        </Panel>

                        <Panel>
                            <PanelTitle title="Credits Availiable" />
                            <PanelContentBoundary
                                capability={[
                                    "servers-view",
                                    "hubs-members-view",
                                ]}
                            >
                                <CreditsContent />
                            </PanelContentBoundary>
                        </Panel>
                    </div>
                </PageContent>
            </PageBody>
        </ResourceLayout>
    );
}

export function CurrentPlan() {
    const currentTier = useGetCurrentPlanTier();

    const { data: activeServices, error: servicesError } =
        useGetBillingServicesQuery({});

    const hub = useAppSelector(selectActiveHub);

    const activeTierPrice = activeServices?.data?.find((as) => !!as.item.tier)
        ?.item.tier?.price.mills;

    const form = useForm<CreateOrderApiArg["body"]>({
        defaultValues: getFormDefaults(hub),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, hub, (h) => getFormDefaults(h));

    if (servicesError) {
        throw servicesError;
    }

    return (
        <>
            {currentTier ? (
                <PlanTier
                    tier={currentTier}
                    priceOverride={
                        activeTierPrice
                            ? {
                                  price: activeTierPrice,
                              }
                            : undefined
                    }
                />
            ) : (
                <>
                    <div
                        className={classNames(
                            skeletonStyles,
                            "mb-6 h-16 w-full"
                        )}
                    />
                </>
            )}
        </>
    );
}
