import {
    Container,
    useGetImageQuery,
    useGetImageSourceQuery,
    useGetVirtualMachineQuery,
} from "~/services/cycle";
import {
    CopyInput,
    SectionDisabledControl,
    FormField,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import { Link, To } from "react-router-dom";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { preprocessCountsData } from "@cycleplatform/ui/components/charts";
import { RecentActivityLog } from "~/components/logs/activity";
import {
    SkeletonButton,
    SkeletonCircle,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { ContainerDialogSearchParams } from "../searchparams";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { PageControl } from "@cycleplatform/ui/components/pagination";
import { usePagination } from "@cycleplatform/ui/hooks";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import classNames from "classnames";
import { PublicNetworkDiagram } from "./PublicNetworkDiagram";
import { PublicDomainsTable } from "./PublicDomainsTable";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { InstanceHistoryBarChart } from "./charts/instanceHistoryBarChart/InstanceHistoryBarChart";
import { ResourceStatePieChart } from "~/components/common/charts/ResourceStatePieChart";
import { EventChartList } from "~/components/security/events/EventChartList";
import { extractPrivateIPs } from "@cycleplatform/core/modules/environments/resources/networks";
import { ScopedVariablesSummary } from "./scopedVariablesSummary/ScopedVariablesSummary";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import {
    isServiceContainer,
    isHypervisorContainer,
} from "@cycleplatform/core/modules/containers/util";
import { useContext } from "react";
import { ContainerDialogContext } from "../context";

type ContainerDashboardTabProps = {
    container: Container | undefined;
};

export function ContainerDashboardTab({
    container,
}: ContainerDashboardTabProps) {
    const pagination = usePagination(
        10,
        ContainerDialogSearchParams.activityPage
    );

    const ips = container ? extractPrivateIPs(container) : null;

    return (
        <SectionDisabledControl
            className="flex"
            disabled={container?.state.current === "deleted"}
            heightFull
        >
            <DialogPageBody>
                <div className="w-2/3 overflow-y-auto">
                    <PageContent className="flex grow">
                        <Panel>
                            <PanelTitle title="Private Network" />
                            <PanelContent>
                                <InputRow>
                                    <FormField label="private network">
                                        {container ? (
                                            <div>
                                                <CopyInput
                                                    value={
                                                        ips?.ipv6 ||
                                                        "Loading..."
                                                    }
                                                    prefix="IPv6"
                                                />
                                                {ips?.ipv4 && (
                                                    <CopyInput
                                                        value={ips.ipv4}
                                                        prefix="IPv4"
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <SkeletonButton />
                                        )}
                                    </FormField>
                                    <FormField label="hostname">
                                        {container ? (
                                            <CopyInput
                                                value={
                                                    container.config.network
                                                        .hostname
                                                }
                                            />
                                        ) : (
                                            <SkeletonButton />
                                        )}
                                    </FormField>
                                </InputRow>
                            </PanelContent>
                        </Panel>

                        <Panel>
                            <PanelTitle title="Public Network" />
                            <PanelContentBoundary>
                                <PublicNetworkDiagram container={container} />

                                {container?.config?.network?.public ===
                                "enable" ? (
                                    <div className="mt-4">
                                        <PublicDomainsTable
                                            domains={container?.meta?.domains}
                                            container={container}
                                        />
                                    </div>
                                ) : null}
                            </PanelContentBoundary>
                        </Panel>

                        <Panel>
                            <PanelTitle title="Instances" />
                            {container?.state?.current !== "deleted" ? (
                                <PanelContent className="h-72 items-center">
                                    <div className="flex items-center gap-4">
                                        <div className="flex w-48 justify-center">
                                            {container?.meta
                                                ?.instances_count ? (
                                                <div className="h-36 w-36 ">
                                                    <ResourceStatePieChart
                                                        data={preprocessCountsData(
                                                            container.meta
                                                                ?.instances_count
                                                        )}
                                                        tooltipOffset={-100}
                                                    />
                                                </div>
                                            ) : (
                                                <SkeletonCircle className="!h-24 !w-24" />
                                            )}
                                        </div>
                                        <div
                                            className={classNames(
                                                "h-60 w-full"
                                            )}
                                        >
                                            <InstanceHistoryBarChart
                                                containerId={container?.id}
                                                className="h-60 w-full"
                                            />
                                        </div>
                                    </div>
                                </PanelContent>
                            ) : (
                                <EmptyResource
                                    title="No Instances"
                                    icon={NavIcons["instances"]}
                                    details="This container has been deleted, and has no instances."
                                />
                            )}
                        </Panel>

                        <Panel>
                            <PanelTitle title="Image" />
                            <PanelContentBoundary
                                capability={"images-sources-view"}
                            >
                                <ImageSection container={container} />
                            </PanelContentBoundary>
                        </Panel>

                        <Panel>
                            <PanelTitle
                                title="Applied Variables"
                                help="Variables in order of heirarchy from highest to lowest."
                            />
                            <PanelContentBoundary stretch>
                                <ScopedVariablesSummary container={container} />
                            </PanelContentBoundary>
                        </Panel>
                    </PageContent>
                </div>

                <PageAside className="w-1/2">
                    {isHypervisorContainer(container) ? (
                        <VirtualMachinePanel container={container} />
                    ) : null}

                    <ServiceContainerPanel container={container} />
                    <div className="w-full">
                        <Panel className="">
                            <PanelTitle title="Events" />
                            <PanelContentBoundary>
                                <EventChartList
                                    criteria={{
                                        "metadata.type": {
                                            $ne: "audit",
                                        },
                                        "labels.container_id": container?.id,
                                    }}
                                    skip={!container?.id}
                                />
                            </PanelContentBoundary>
                        </Panel>
                    </div>

                    <div className="w-full">
                        <Panel>
                            <PanelTitle
                                className="bg-cycle-white-accent dark:bg-cycle-black-accent sticky -top-5 z-10 flex justify-between"
                                title="Recent Activity"
                            >
                                <PageControl page={pagination} />
                            </PanelTitle>

                            <PanelContentBoundary className="flex max-h-full px-0 pt-0">
                                <RecentActivityLog
                                    pagination={pagination}
                                    containerId={container?.id}
                                />
                            </PanelContentBoundary>
                        </Panel>
                    </div>
                </PageAside>
            </DialogPageBody>
        </SectionDisabledControl>
    );
}

function VirtualMachinePanel({
    container,
}: {
    container: Container | undefined;
}) {
    const { data: vm } = useGetVirtualMachineQuery(
        {
            virtualMachineId: container?.image?.extension?.id || "",
        },
        { skip: !container?.image?.extension?.id }
    );

    return (
        <div className="w-full">
            <Panel>
                <PanelTitle title="Virtual Machine" />
                <PanelContent>
                    <div className="flex items-center justify-between">
                        <Link
                            className={!container ? skeletonStyles : ""}
                            to={generateDialogLink("virtual-machine", {
                                "dialog-id": container?.image?.extension?.id,
                            })}
                        >
                            {container?.image?.extension?.id}
                        </Link>

                        <ResourceStateBadge state={vm?.data?.state} />
                    </div>
                </PanelContent>
            </Panel>
        </div>
    );
}

function ServiceContainerPanel({
    container,
}: {
    container: Container | undefined;
}) {
    if (!isServiceContainer(container)) {
        return null;
    }

    return (
        <div className="w-full">
            <Panel>
                <PanelTitle title="Environment Service" />
                <PanelContent>
                    <div className="flex items-center justify-between">
                        <ServiceContainerLink container={container} />

                        <ResourceStateBadge state={container?.state} />
                    </div>
                </PanelContent>
            </Panel>
        </div>
    );
}

function ServiceContainerLink({
    container,
}: {
    container: Container | undefined;
}) {
    const service = container?.image.service;

    const { environment } = useContext(ContainerDialogContext);

    switch (service) {
        case "loadbalancer":
            return (
                <Link
                    className={!container ? skeletonStyles : ""}
                    to={generateDialogLink("environment-lb-manage", {
                        "dialog-env": environment?.id || "",
                    })}
                >
                    {environment?.name} Load Balancer
                </Link>
            );
        case "vpn":
            return (
                <Link
                    className={!container ? skeletonStyles : ""}
                    to={generateDialogLink("environment-vpn-manage", {
                        "dialog-env": environment?.id || "",
                    })}
                >
                    {environment?.name} VPN
                </Link>
            );
        case "discovery":
            return (
                <Link
                    className={!container ? skeletonStyles : ""}
                    to={generateDialogLink("environment-discovery-manage", {
                        "dialog-env": environment?.id || "",
                    })}
                >
                    {environment?.name} Discovery
                </Link>
            );
        case "scheduler":
            return (
                <Link
                    className={!container ? skeletonStyles : ""}
                    to={generateDialogLink("environment-scheduler-manage", {
                        "dialog-env": environment?.id || "",
                    })}
                >
                    {environment?.name} Scheduler
                </Link>
            );
    }
}

const ImageSection = ({ container }: { container?: Container }) => {
    const { data: image } = useGetImageQuery({
        imageId: container?.image?.id || "",
    });

    const { data: imageSource } = useGetImageSourceQuery(
        {
            sourceId: image?.data?.source?.details?.id || "",
        },
        { skip: !image?.data?.source?.details?.id }
    );

    return (
        <>
            <FormField label="name">
                {container ? (
                    <>
                        {container?.image.service ? (
                            <div>{container.name}</div>
                        ) : (
                            <Link
                                to={generateDialogLink("image", {
                                    "dialog-image-id": image?.data?.id || "",
                                })}
                            >
                                {image?.data?.name}
                            </Link>
                        )}
                    </>
                ) : (
                    <SkeletonText size="lg" />
                )}
            </FormField>

            <FormField label="source">
                {container ? (
                    <>
                        {container.image.service ? (
                            <span>Provided by Cycle</span>
                        ) : (
                            <span>
                                Imported image from source{" "}
                                <Link
                                    to={`images/sources/${imageSource?.data?.id}`}
                                >
                                    {imageSource?.data?.name}
                                </Link>
                            </span>
                        )}
                    </>
                ) : (
                    <SkeletonText size="lg" />
                )}
            </FormField>
        </>
    );
};
