import { PrefetchOptions } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useAppDispatch } from "~/hooks";
import { cycleApi, ResourceCountSummary } from "../__generated";
import { $trace } from "@cycleplatform/core/util/log";

export type CycleApiTag = typeof tagTypes[number];

export function usePrefetchImmediately<
    T extends keyof typeof cycleApi.endpoints
>(
    endpoint: T,
    arg: Parameters<typeof cycleApi.endpoints[T]["initiate"]>[0],
    options: PrefetchOptions = {}
) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(cycleApi.util.prefetch(endpoint as any, arg, options));
    }, []);
}

type Tag = {
    type: typeof tagTypes[number];
    id: string;
};

export const tagTypes = [
    /** The websocket connection */
    "Account.Notifications",

    // Announcements
    "Announcement",

    // Billing
    "Billing.Credit",
    "Billing.Discount",
    "Billing.Invoice",
    "Billing.Method",
    "Billing.Order",
    "Billing.Service",

    // Containers
    "Container",
    /* Hub activity, scoped to a container */
    "Container.Activity",
    "Container.Backup",
    // Indicates data of the number of instances, keyed by their state
    "Container.InstanceStateCounts",
    "Container.InstanceTelemetry",

    // Instances
    "Instance",
    "Instance.SSH",
    "Instance.Console",
    "Instance.Telemetry",

    // DNS
    "Dns.Certificate",
    "Dns.Zone",
    "Dns.Record",

    // Environments
    "Environment",
    /* Hub activity, scoped to an env */
    "Environment.Activity",
    "Environment.Service",
    "Environment.ScopedVariable",
    "Environment.Summary",
    "Environment.Deployments",
    // Indicates data of the number of containers, keyed by their state
    "Environment.ContainerStateCounts",
    "Environment.Vpn.User",

    // Hubs
    "Hub",
    /** Ties a query specifically to a hub */
    "Hub.Resource",
    /* Hub activity, when no env/container specified */
    "Hub.Activity",
    "Hub.ApiKey",
    "Hub.Membership",
    "Hub.Integration",
    "Hub.Provider",
    "Hub.Invite",
    "Hub.Role",
    /** The websocket connection */
    "Hub.Notifications",
    "Hub.Event",

    // Images
    "Image",
    "Image.Source",
    // Infrastructure
    "Infrastructure.Ip",
    "Infrastructure.IpPool",
    "Infrastructure.Provider",
    "Infrastructure.Server",
    "Infrastructure.Cluster",
    "Infrastructure.AutoscaleGroup",

    // Jobs
    "Job",

    // Pipelines
    "Pipeline",
    "Pipeline.Key",
    "Pipeline.Run",

    // SDN
    "Network.Sdn",

    "Secret",

    // Stacks
    "Stack",
    "Stack.Build",

    // Acccounts
    "PersonalAccount",
    "PublicAccount",
    "SearchIndex",

    // VMs
    "VirtualMachine",
    "VirtualMachineSshKey",
] as const;

function providesList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T,
    /** If false, will add a tag that is invalidated whenever the active hub is changed */
    shouldIgnoreHubChange = false
) {
    let tags = [{ type: tagType, id: "LIST" }];

    if (!shouldIgnoreHubChange) {
        tags = [...tags, { type: "Hub.Resource" as T, id: "ACTIVE" }];
    }

    if (resultsWithIds) {
        tags = [
            ...tags,
            ...resultsWithIds.map(({ id }) => ({
                type: tagType,
                id: id as string,
            })),
        ];
    }

    return tags;
}

/** Creates tag + associates api call with hub so if hub changes, it's reloaded */
function providesHubResource<
    R extends { id: string | number },
    T extends string
>(resultWithId: R | undefined, tagType: T) {
    if (resultWithId) {
        return [
            { type: "Hub.Resource" as T, id: "ACTIVE" },
            { type: tagType, id: resultWithId?.id as string },
        ];
    }
    return [];
}

function providesIncludeTags<
    R extends Record<string | number, unknown>,
    T extends string
>(includesObject: R | undefined, tagType: T) {
    if (!includesObject) {
        return [];
    }
    return Object.keys(includesObject).map((id) => ({ id, type: tagType }));
}

type TagTypes = typeof tagTypes[number];

export function applyCycleApiTagging() {
    cycleApi.enhanceEndpoints({
        endpoints: {
            // Accounts
            getAccount: {
                providesTags: () => ["PersonalAccount"],
            },

            enableTwoFactorAuth: {
                invalidatesTags: ["PersonalAccount"],
            },

            disableTwoFactorAuth: {
                // disable hub to force recheck if 2FA is required
                invalidatesTags: ["PersonalAccount", "Hub"],
            },

            updateAccount: {
                invalidatesTags: ["PersonalAccount"],
            },

            getSearchIndex: {
                providesTags: () => ["SearchIndex"],
            },

            // Announcements
            getAnnouncements: {
                providesTags: (result) =>
                    providesList(result?.data, "Announcement"),
            },

            // Hubs
            getHubActivity: {
                providesTags: (_req, _err, args) => {
                    // If we pass one of these filters,
                    // we only really care about it from that perspective.
                    if (args.filter?.container) {
                        return [
                            {
                                type: "Container.Activity",
                                id: args.filter.container as string,
                            },
                        ];
                    }
                    if (args.filter?.environment) {
                        return [
                            {
                                type: "Environment.Activity",
                                id: args.filter.environment as string,
                            },
                        ];
                    }

                    return [{ type: "Hub.Activity" as const, id: "ACTIVE" }];
                },
            },
            // Jobs
            getJobs: {
                providesTags: (result) => providesList(result?.data, "Job"),
            },
            // Environments
            getEnvironments: {
                providesTags: (result) => {
                    const metaTags =
                        result?.data
                            ?.map((e) => {
                                if (e.meta?.containers_count) {
                                    return {
                                        id: e.id,
                                        type: "Environment.ContainerStateCounts" as const,
                                    };
                                }

                                return null;
                            })
                            .filter(
                                (
                                    t
                                ): t is {
                                    id: string;
                                    type: "Environment.ContainerStateCounts";
                                } => t !== null
                            ) || [];

                    return [
                        ...metaTags,
                        ...providesList(result?.data, "Environment"),
                        ...providesIncludeTags(
                            result?.includes?.creators?.accounts,
                            "PublicAccount"
                        ),
                    ];
                },
            },
            getEnvironment: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Environment"),
            },

            getEnvironmentSummary: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Environment.Summary"),
            },
            createEnvironment: {
                invalidatesTags: [{ type: "Environment", id: "LIST" }],
            },

            updateEnvironment: {
                invalidatesTags: (_, __, args) => [
                    { type: "Environment", id: args.environmentId },
                ],
            },

            getEnvironmentDeployments: {
                providesTags: (_, __, args) => [
                    ...providesHubResource(
                        { id: args.environmentId },
                        "Environment"
                    ),
                    {
                        type: "Environment.Deployments",
                        id: args.environmentId,
                    },
                ],
            },

            getVpnUsers: {
                providesTags: (result) =>
                    providesList(result?.data, "Environment.Vpn.User"),
            },
            getVpnService: {
                providesTags: (_, __, args) => [
                    {
                        type: "Environment.Service",
                        id: args.environmentId,
                    },
                ],
            },

            // Environments - Scoped Vars
            getScopedVariables: {
                providesTags: (result) =>
                    providesList(result?.data, "Environment.ScopedVariable"),
            },

            getScopedVariable: {
                providesTags: (result) =>
                    providesHubResource(
                        result?.data,
                        "Environment.ScopedVariable"
                    ),
            },

            updateScopedVariable: {
                invalidatesTags: [
                    { type: "Environment.ScopedVariable", id: "LIST" },
                ],
            },

            // Environments - LB
            getLoadBalancerService: {
                providesTags: (_, __, args) => [
                    {
                        type: "Environment.Service",
                        id: args.environmentId,
                    },
                ],
            },

            // Containers
            getContainers: {
                merge: (currentCache, newItems, { arg }) => {
                    // Here, we rely 100% on notification socket for instance state changes.
                    // If we were to allow the instance_counts object to be merged in from the API
                    // (say, during a background refresh etc) there's a chance our counts could be thrown off,
                    // as what we fetch may be invalidated by notifications while en-route, overriding the change
                    // once the request is received. This block ensures that even if we update containers, we
                    // continue to rely on the existing instances_count since they should be accurate. Of course,
                    // this containers list should be refetched with Hub.Resource tag if the socket were to ever
                    // disconnect.
                    if (!arg.meta || !arg.meta.includes("instances_count")) {
                        currentCache.data = newItems.data;
                        return;
                    }

                    $trace(
                        "merging existing instances count into new containers list"
                    );

                    const instanceCountsByEnvId =
                        currentCache.data?.reduce((acc, cur) => {
                            if (cur.meta?.instances_count) {
                                acc[cur.id] = cur.meta?.instances_count;
                            }
                            return acc;
                        }, {} as Record<string, ResourceCountSummary>) || {};

                    currentCache.data = (newItems.data || []).map((e) => ({
                        ...e,
                        meta: {
                            ...e.meta,
                            instances_count:
                                instanceCountsByEnvId[e.id] ||
                                e.meta?.instances_count,
                        },
                    }));
                    currentCache.includes = newItems.includes;
                },
                providesTags: (result, _, args) => {
                    const tags: Array<{ id: string; type: TagTypes }> = [
                        ...providesList(result?.data, "Container"),
                        ...providesIncludeTags(
                            result?.includes?.images,
                            "Image"
                        ),
                        ...providesIncludeTags(
                            result?.includes?.stacks,
                            "Stack"
                        ),
                    ];

                    if (args.meta?.includes("instances_count")) {
                        // If we show i.e. the instance circle chart,
                        // we tend to use the more up to date instance count meta
                        // on the container. It should stay up to date.
                        result?.data?.forEach((c) => {
                            tags.push({
                                type: "Container.InstanceStateCounts",
                                id: c.id,
                            });
                        });
                    }

                    return tags;
                },
            },
            getContainer: {
                merge: (currentCache, newItem, { arg }) => {
                    // Here, we rely 100% on notification socket for instance state changes.
                    // If we were to allow the instance_counts object to be merged in from the API
                    // (say, during a background refresh etc) there's a chance our counts could be thrown off,
                    // as what we fetch may be invalidated by notifications while en-route, overriding the change
                    // once the request is received. This block ensures that even if we update containers, we
                    // continue to rely on the existing instances_count since they should be accurate. Of course,
                    // this container should be refetched with Hub.Resource tag if the socket were to ever
                    // disconnect.
                    if (
                        !arg.meta ||
                        !arg.meta.includes("instances_count") ||
                        !currentCache.data?.meta?.instances_count ||
                        !newItem.data?.meta?.instances_count
                    ) {
                        currentCache.data = newItem.data;
                        return;
                    }

                    $trace(
                        `merging existing instances count into container ${currentCache.data?.id}`
                    );

                    currentCache.data = {
                        ...newItem.data,
                        meta: {
                            ...newItem.data.meta,
                            instances_count:
                                currentCache.data.meta.instances_count,
                        },
                    };
                },
                providesTags: (result, _, args) => {
                    const tags = providesHubResource(
                        result?.data,
                        "Container" as TagTypes
                    );

                    if (args.meta?.includes("instances_count")) {
                        // If we show i.e. the instance circle chart,
                        // we tend to use the more up to date instance count meta
                        // on the container. It should stay up to date.
                        tags.push({
                            type: "Container.InstanceStateCounts",
                            id: args.containerId,
                        });
                    }
                    return tags;
                },
            },

            getContainerBackups: {
                providesTags: (result) =>
                    providesList(result?.data, "Container.Backup"),
            },

            getContainerBackup: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Container.Backup"),
            },

            createContainerJob: {
                invalidatesTags: (result) => [
                    {
                        type: "Container",
                        id: result?.data?.job?.tasks?.[0]?.input?.id,
                    },
                ],
            },

            getInvoices: {
                providesTags: (result) =>
                    providesList(result?.data, "Billing.Invoice"),
            },

            getInvoice: {
                providesTags: (result) =>
                    providesHubResource(
                        result?.data,
                        "Billing.Invoice" as const
                    ),
            },

            getBillingMethods: {
                providesTags: (result) => {
                    const primary = result?.data?.find((m) => m.primary);
                    const listTags = providesList(
                        result?.data,
                        "Billing.Method"
                    );
                    if (primary) {
                        listTags.push({
                            type: "Billing.Method",
                            id: "PRIMARY",
                        });
                    }
                    return listTags;
                },
            },

            getBillingMethod: {
                providesTags: (result) => {
                    const tags = providesHubResource(
                        result?.data,
                        "Billing.Method" as const
                    );

                    if (result?.data.primary) {
                        tags.push({
                            type: "Billing.Method",
                            id: "PRIMARY",
                        });
                    }
                    return tags;
                },
            },

            updateBillingMethod: {
                invalidatesTags: (result) => [
                    {
                        type: "Billing.Method",
                        id: result?.data?.id,
                    },
                    {
                        type: "Billing.Method",
                        id: "PRIMARY",
                    },
                ],
            },

            getBillingServices: {
                providesTags: (result) =>
                    providesList(result?.data, "Billing.Service"),
            },

            // DNS

            getDnsZoneRecords: {
                providesTags: (result) =>
                    providesList(result?.data, "Dns.Record"),
            },

            getDnsZones: {
                providesTags: (result) =>
                    providesList(result?.data, "Dns.Zone"),
            },
            getDnsZone: {
                providesTags: (result, _, args) =>
                    providesHubResource(result?.data, "Dns.Zone" as const),
            },

            // DNS - TLS
            getUserSuppliedCertificates: {
                providesTags: (result) =>
                    providesList(result?.data, "Dns.Certificate"),
            },
            getUserSuppliedCertificate: {
                providesTags: (result, _) =>
                    providesHubResource(
                        result?.data,
                        "Dns.Certificate" as const
                    ),
            },
            uploadUserSuppliedCertificate: {
                invalidatesTags: [{ type: "Dns.Certificate", id: "LIST" }],
            },

            // Infrastructure - Servers
            getServers: {
                providesTags: (result) =>
                    providesList(result?.data, "Infrastructure.Server"),
            },

            getServerInstances: {
                providesTags: (result) =>
                    providesList(result?.data, "Instance"),
            },

            getServer: {
                providesTags: (result) =>
                    providesHubResource(
                        result?.data,
                        "Infrastructure.Server" as const
                    ),
            },

            getClusters: {
                providesTags: (result) =>
                    providesList(
                        result?.data?.map((r) => ({
                            id: r.id,
                        })),
                        "Infrastructure.Cluster"
                    ),
            },

            getCluster: {
                providesTags: (result) =>
                    providesHubResource(
                        result?.data,
                        "Infrastructure.Cluster" as const
                    ),
            },

            getAutoScaleGroups: {
                providesTags: (result) =>
                    providesList(result?.data, "Infrastructure.AutoscaleGroup"),
            },
            getAutoScaleGroup: {
                providesTags: (result, _, args) =>
                    providesHubResource(
                        result?.data,
                        "Infrastructure.AutoscaleGroup" as const
                    ),
            },
            getInfrastructureIpPools: {
                providesTags: (result) =>
                    providesList(result?.data, "Infrastructure.Ip"),
            },

            // Images
            getImageSources: {
                providesTags: (result) =>
                    providesList(result?.data, "Image.Source"),
            },

            getImageSource: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Image.Source"),
            },
            getImages: {
                providesTags: (result) => providesList(result?.data, "Image"),
            },

            getImage: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Image" as const),
            },

            // Instances
            getInstances: {
                providesTags: (result) =>
                    providesList(result?.data, "Instance"),
            },
            getInstanceConsoleStreamAuth: {
                keepUnusedDataFor: 0,
            },

            // Settings
            getRoles: {
                providesTags: (result) =>
                    providesList(result?.data, "Hub.Role"),
            },

            getRole: {
                providesTags: (result) => [
                    { type: "Hub.Role", id: result?.data?.id },
                ],
            },
            getHubMembers: {
                providesTags: (result) =>
                    providesList(result?.data, "Hub.Membership"),
            },

            getHubMembership: {
                providesTags: (result) => [
                    { type: "Hub.Membership", id: result?.data?.id },
                ],
            },

            getIntegrations: {
                providesTags: (result) =>
                    providesList(result?.data, "Hub.Integration"),
            },

            getIntegration: {
                providesTags: (result) => [
                    { type: "Hub.Integration", id: result?.data?.id },
                ],
            },

            deleteHubMember: {
                invalidatesTags: [{ type: "Hub.Membership", id: "LIST" }],
            },

            createHub: {
                invalidatesTags: [{ type: "Hub", id: "LIST" }],
            },

            createHubInvite: {
                invalidatesTags: [{ type: "Hub.Invite", id: "LIST" }],
            },

            deleteHubInvite: {
                invalidatesTags: [{ type: "Hub.Invite", id: "LIST" }],
            },

            getHubInvites: {
                providesTags: (result) =>
                    providesList(result?.data, "Hub.Invite"),
            },

            getAccountInvites: {
                providesTags: (result) =>
                    providesList(result?.data, "Hub.Invite"),
            },

            getApiKeys: {
                providesTags: (result) =>
                    providesList(result?.data, "Hub.ApiKey"),
            },

            getApiKey: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Hub.ApiKey"),
            },

            getNetworks: {
                providesTags: (result) =>
                    providesList(result?.data, "Network.Sdn"),
            },
            getNetwork: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Network.Sdn" as const),
            },

            // Stacks
            getStacks: {
                providesTags: (result) => providesList(result?.data, "Stack"),
            },
            getStack: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Stack" as const),
            },

            getStackBuilds: {
                providesTags: (result) =>
                    providesList(result?.data, "Stack.Build"),
            },

            getStackBuild: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Stack.Build" as const),
            },
            deleteStack: {
                invalidatesTags: [{ type: "Stack", id: "LIST" }],
            },

            deleteStackBuild: {
                invalidatesTags: [{ type: "Stack.Build", id: "LIST" }],
            },

            // pipelines

            getPipelines: {
                providesTags: (result) =>
                    providesList(result?.data, "Pipeline"),
            },

            getPipeline: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Pipeline" as const),
            },

            getPipelineRuns: {
                providesTags: (result) =>
                    providesList(result?.data, "Pipeline.Run"),
            },

            getPipelineRun: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Pipeline.Run" as const),
            },

            getPipelineTriggerKeys: {
                providesTags: (result) =>
                    providesList(result?.data, "Pipeline.Key"),
            },

            getEvents: {
                providesTags: ["Hub.Event"],
            },
            generateAggregatedEvents: {
                providesTags: ["Hub.Event"],
            },

            getHubs: {
                providesTags: (result) => providesList(result?.data, "Hub"),
            },
            getHub: {
                providesTags: (result) =>
                    providesHubResource(result?.data, "Hub" as const),
            },

            // VMs
            getVirtualMachines: {
                providesTags: (result) => {
                    const tags = [
                        ...providesList(result?.data, "VirtualMachine"),
                        ...providesIncludeTags(
                            result?.includes?.containers,
                            "Container"
                        ),
                        ...providesIncludeTags(
                            result?.includes?.environments,
                            "Environment"
                        ),
                        ...providesIncludeTags(
                            result?.includes?.clusters,
                            "Infrastructure.Cluster"
                        ),
                    ];
                    return tags;
                },
            },
            getVirtualMachine: {
                providesTags: (result) => {
                    const tags: Tag[] = [
                        ...providesHubResource(result?.data, "VirtualMachine"),
                        ...providesIncludeTags(
                            result?.includes?.containers,
                            "Container"
                        ),
                        ...providesIncludeTags(
                            result?.includes?.environments,
                            "Environment"
                        ),
                        ...providesIncludeTags(
                            result?.includes?.clusters,
                            "Infrastructure.Cluster"
                        ),
                    ];
                    if (result?.data?.meta?.ips) {
                        result.data.meta.ips.forEach((ip) => {
                            tags.push({
                                type: "Infrastructure.Ip",
                                id: ip.id,
                            });
                        });
                    }
                    return tags;
                },
            },
            createVirtualMachine: {
                invalidatesTags: [{ type: "VirtualMachine", id: "LIST" }],
            },
            updateVirtualMachine: {
                invalidatesTags: (_, __, args) => [
                    { type: "VirtualMachine", id: args.virtualMachineId },
                ],
            },
            createVirtualMachineJob: {
                invalidatesTags: (result) => [
                    {
                        type: "VirtualMachine",
                        id: result?.data?.job?.tasks?.[0]?.input?.id,
                    },
                ],
            },
            getVirtualMachineSshKeys: {
                providesTags: (result) => {
                    const tags = [
                        ...providesList(result?.data, "VirtualMachineSshKey"),
                        ...providesIncludeTags(
                            result?.includes?.environments,
                            "Environment"
                        ),
                    ];
                    return tags;
                },
            },
            getVirtualMachineSshKey: {
                providesTags: (result) => {
                    const tags = [
                        ...providesHubResource(
                            result?.data,
                            "VirtualMachineSshKey"
                        ),
                        ...providesIncludeTags(
                            result?.includes?.environments,
                            "Environment"
                        ),
                    ];
                    return tags;
                },
            },
            createVirtualMachineSshKey: {
                invalidatesTags: [{ type: "VirtualMachineSshKey", id: "LIST" }],
            },
            updateVirtualMachineSshKey: {
                invalidatesTags: (_, __, args) => [
                    { type: "VirtualMachineSshKey", id: args.sshKeyId },
                ],
            },
        },
    });
}
