import {
    CreateIntegrationApiArg,
    useCreateIntegrationJobMutation,
    useCreateIntegrationMutation,
    useGetAvailableIntegrationsQuery,
} from "~/services/cycle";
import {
    RhfFormField,
    FormSection,
    FormSectionHeader,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    TextAreaInput,
    required,
    isRegExMatch,
} from "@cycleplatform/ui/components/forms";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import {
    LoaderButton,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { faAdd } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { clearDialogParams, getAllDialogSearchParams } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { AuthKey } from "@cycleplatform/core/modules/hub/integrations";
import { useMemo } from "react";
import { findAvailableIntegration } from "@cycleplatform/core/modules/hub/integrations/helpers";
import { RhfIdentifierInput } from "~/components/forms/RhfIdentifierInput";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

export function CreateIntegrationForm() {
    const params = getAllDialogSearchParams<"integration-create">();
    const nav = useNavigate();
    const form = useForm<CreateIntegrationApiArg["body"]>({
        defaultValues: {
            vendor: params["dialog-integration-vendor"] || "",
            identifier: params["dialog-integration-vendor"] || "",
        },
        ...rhfConfig,
    });
    const {
        register,
        formState: { isSubmitting },
    } = form;

    const { data: availableIntegrations, error } =
        useGetAvailableIntegrationsQuery();

    if (error) {
        throw error;
    }

    const availableIntegration = useMemo(() => {
        return findAvailableIntegration(
            availableIntegrations?.data,
            params["dialog-integration-vendor"]
        );
    }, [availableIntegrations?.data]);

    const [createIntegration] = useCreateIntegrationMutation();
    const [createIntegrationJob] = useCreateIntegrationJobMutation();

    const onCreate = (data: CreateIntegrationApiArg["body"]) => {
        const authConfig = !!data?.auth?.base64_config
            ? {
                  ...data?.auth,
                  base64_config: window.btoa(data?.auth?.base64_config),
              }
            : data?.auth;

        return createIntegration({
            body: {
                ...data,
                auth: authConfig,
            },
        })
            .unwrap()
            .then((i) => {
                if (!availableIntegration?.supports_verification) {
                    return;
                }
                return createIntegrationJob({
                    integrationId: i?.data?.id,
                    body: {
                        action: "verify",
                    },
                });
            })
            .then(() => nav(clearDialogParams()))
            .catch(handleSubmitError(form.setError));
    };
    return (
        <RhfFormProvider {...form} onSubmit={form.handleSubmit(onCreate)}>
            <div className="w-full ">
                <FormSectionHeader header="General" />
                <FormSection>
                    <RhfFormField label="name" name="name" required>
                        <TextInput {...register(`name`, { ...required() })} />
                    </RhfFormField>
                    <RhfFormField label="vendor" name="vendor" required>
                        <TextInput {...register(`vendor`)} disabled />
                    </RhfFormField>
                    <RhfIdentifierInput
                        name={`identifier`}
                        label="Identifier"
                        required
                    />

                    {Object.entries(
                        availableIntegration?.fields?.extra || {}
                    ).map(([k, val]) => (
                        <RhfFormField
                            label={val?.description}
                            name={`extra.${k}`}
                            required={val?.required}
                            key={`extra.${k}`}
                        >
                            <TextInput
                                {...register(`extra.${k}`, {
                                    ...(val?.required ? required() : {}),
                                    validate: {
                                        ...isRegExMatch(val?.regex),
                                    },
                                })}
                            />
                        </RhfFormField>
                    ))}
                </FormSection>
                {availableIntegration?.fields?.auth ? (
                    <>
                        <FormSectionHeader header="Auth" />
                        <FormSection>
                            {Object.entries(
                                availableIntegration?.fields?.auth || {}
                            ).map(([k, val]) => {
                                return (
                                    <RhfFormField
                                        label={val?.description}
                                        name={`auth.${k}`}
                                        required={val?.required}
                                        key={`auth.${k}`}
                                    >
                                        {k === "base64_config" ? (
                                            <TextAreaInput
                                                rows={10}
                                                {...register(
                                                    `auth.${k as AuthKey}`,
                                                    {
                                                        ...(val?.required
                                                            ? required()
                                                            : {}),
                                                        validate: {
                                                            ...isRegExMatch(
                                                                val?.regex
                                                            ),
                                                        },
                                                    }
                                                )}
                                            />
                                        ) : (
                                            <TextInput
                                                {...register(
                                                    `auth.${k as AuthKey}`,
                                                    {
                                                        ...(val?.required
                                                            ? required()
                                                            : {}),
                                                        validate: {
                                                            ...isRegExMatch(
                                                                val?.regex
                                                            ),
                                                        },
                                                    }
                                                )}
                                            />
                                        )}
                                    </RhfFormField>
                                );
                            })}
                        </FormSection>
                    </>
                ) : null}
            </div>

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={form.handleSubmit(onCreate)}
                    icon={faAdd}
                    flavor="primary"
                >
                    Add
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
