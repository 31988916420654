import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import {
    Environment,
    GetEnvironmentsApiArg,
    useGetEnvironmentsQuery,
} from "~/services/cycle";
import { ResourceComboBox } from "../common/forms";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { Badge } from "@cycleplatform/ui/components/badges";

type EnvironmentSelectProps<MULTI extends boolean = false> = {
    value: MULTI extends true ? Array<string> : string | undefined | null;
    isNullable?: boolean;
    onChange: MULTI extends true
        ? (ids: string[], environments: Environment[]) => void
        : (
              id: string | undefined | null,
              environment: Environment | undefined
          ) => void;
    skip?: boolean;
    placeholder?: string;
    multiple?: MULTI;
    disabled?: boolean;
    filter?: GetEnvironmentsApiArg["filter"];
    filterResult?: (es: Environment[]) => Environment[];
    required?: boolean;
    className?: string;
};

export function EnvironmentSelect<MULTI extends boolean = false>({
    value,
    onChange,
    skip,
    multiple,
    isNullable,
    placeholder,
    disabled,
    filter,
    filterResult = (envs) => envs,
    className,
}: EnvironmentSelectProps<MULTI>) {
    // TODO - there is a risk that the environment set as 'value' is not on the list
    // returned by the query. May need an additional query for the specific environment
    // if not initially returned and then merge them.
    const { currentData: environments, error } = useGetEnvironmentsQuery(
        {
            sort: ["-about.favorite", "-id"],
            page: {
                number: 1,
                // TODO - infinite scroll
                size: 100,
            },
            filter,
        },
        { skip }
    );

    if (error) {
        throw error;
    }

    return (
        <ResourceComboBox
            className={className}
            filterFields={["id", "name"]}
            value={value}
            multiple={multiple}
            onChange={onChange}
            isNullable={isNullable}
            placeholder={placeholder || "No Environment Selected"}
            disabled={disabled}
            resources={filterResult(environments?.data || [])}
            formatDisplayValue={(e) => {
                if (!e) return "";

                return e.name;
            }}
            formatOption={(e) => {
                return (
                    <FormattedOption label={e.name}>
                        <div className="flex gap-2">
                            <Badge>{e.cluster}</Badge>
                            <ResourceStateBadge state={e.state} />
                        </div>
                    </FormattedOption>
                );
            }}
        />
    );
}
