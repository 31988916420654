import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { useContext, useEffect } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { CreateBillingMethodForm } from "~/components/settings/forms/billingMethod/CreateBillingMethodForm";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
    useGetBillingMethodsQuery,
    useGetBillingTiersQuery,
} from "~/services/cycle";
import { ChooseAnotherHubButton } from "../../buttons/ChooseAnotherHubButton";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import {
    SkeletonHeader,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { FormSection } from "@cycleplatform/ui/components/forms";
import { DeleteHubButton } from "../../buttons/DeleteHubButton";
import { HandleBackButton } from "../../buttons/HandleBackButton";
import { ActivePaymentSection } from "./ActivePayment";
import { HubSetupTypes } from "../../types";

export function HubSetupPaymentStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const dispatch = useAppDispatch();
    const { handleNext, formKey, handleBack } = useContext(
        MultiStepDialogContext
    );
    const { data: methods, isLoading } = useGetBillingMethodsQuery({});

    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );
    const cacheMethod = currentForm?.steps?.[stepIdx];

    const cachedTier = currentForm?.steps?.[0] as HubSetupTypes[0] | undefined;

    useEffect(() => {
        const method = methods?.data?.[0];

        if (!cachedTier) {
            handleBack();
        }

        if (!cacheMethod && method) {
            dispatch(
                addStepData({ data: { method }, idx: stepIdx, key: formKey })
            );
        }
    }, [methods, cacheMethod, cachedTier]);

    if (isLoading) {
        return (
            <DialogColumn className="w-[25rem]">
                <SkeletonHeader />
                <FormSection>
                    <SkeletonText />
                    <SkeletonText />
                    <SkeletonText />
                    <SkeletonText />
                    <SkeletonText />
                </FormSection>
            </DialogColumn>
        );
    }

    if (methods?.data.length) {
        return <ActivePaymentSection method={methods.data[0]} />;
    }

    if (!cachedTier) {
        return null;
    }

    return (
        <DialogColumn>
            <CreateBillingMethodForm
                onSuccess={(method) => {
                    dispatch(
                        addStepData({
                            ...method,
                            idx: stepIdx,
                            key: formKey,
                        })
                    );
                    handleNext();
                }}
                footerButtons={
                    <div className="flex gap-4">
                        <HandleBackButton />
                        <ChooseAnotherHubButton />
                        <DeleteHubButton />
                    </div>
                }
            />
        </DialogColumn>
    );
}
