import {
    CreateBillingMethodApiArg,
    CreateBillingMethodApiResponse,
    useCreateBillingMethodMutation,
    useGetHubQuery,
} from "~/services/cycle";
import { useForm, useWatch } from "react-hook-form";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { RhfCountrySelect } from "~/components/common/forms";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { getDate } from "date-fns";
import { getOrdinalNum } from "@cycleplatform/core/util/number";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";
import { MethodGeneralSection } from "./sections/MethodGeneralSection";
import { ToggleMethodType } from "./sections/ToggleMethodType";
import { CreditCardFormSection } from "./sections/CreditCardFormSection";
import { UsBankFormSection } from "./sections/UsBankFormSection";
import { getDefaultValues } from "./helpers";
import { AccessControlOverlay } from "~/components/common/buttons";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";

export type CreateBillingMethodFormType = CreateBillingMethodApiArg["body"] & {
    method_type: "cc" | "us_bank_acct";
};

export function CreateBillingMethodForm({
    onSuccess,
    footerButtons,
}: {
    onSuccess: (m: CreateBillingMethodApiResponse) => void;
    footerButtons?: React.ReactNode;
}) {
    const form = useForm<CreateBillingMethodFormType>({
        defaultValues: getDefaultValues(),
        ...rhfConfig,
    });

    const hub = useAppSelector(selectActiveHub);

    const {
        handleSubmit,
        register,
        control,
        formState: { isSubmitting, isDirty },
    } = form;

    const [createBillingMethod] = useCreateBillingMethodMutation();

    const onSubmit = (data: CreateBillingMethodFormType) => {
        if (!isDirty) {
            return;
        }

        if ("address" in data) {
            return createBillingMethod({
                body: {
                    name: data.name || "",
                    primary: data.primary || false,
                    address: data.address,
                    credit_card:
                        data.credit_card as CreateBillingMethodApiArg["body"]["credit_card"],
                    us_bank_acct: data.us_bank_acct,
                },
            })
                .unwrap()
                .then(onSuccess)
                .catch(
                    handleSubmitError(form.setError, {
                        sourceOverride: {
                            "credit_card.expiration.year":
                                "credit_card._tmpexpires",
                            "credit_card.expiration.month":
                                "credit_card._tmpexpires",
                        },
                    })
                );
        }
    };

    const methodType = useWatch({
        name: "method_type",
        control,
    });

    const renewalDay = hub?.billing?.term.end
        ? getDate(new Date(hub.billing.term.end))
        : null;

    return (
        <RhfFormProvider {...form}>
            <MethodGeneralSection />

            <ToggleMethodType />
            {methodType === "cc" && <CreditCardFormSection />}
            {methodType === "us_bank_acct" && <UsBankFormSection />}

            <FormSectionHeader header="Address" />
            <FormSection>
                <div className="flex gap-2">
                    <RhfFormField
                        label="country"
                        name="address.country"
                        className="!w-3/4"
                        required
                    >
                        <RhfCountrySelect
                            disabled={methodType === "us_bank_acct"}
                            path={"address.country"}
                            isRequired
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="postal code"
                        name="address.zip"
                        className="!w-1/4"
                        required
                    >
                        <TextInput
                            {...register("address.zip", { ...required() })}
                        />
                    </RhfFormField>
                </div>
            </FormSection>

            {renewalDay ? (
                <div className="rounded-sm border p-2 text-xs">
                    By clicking [Create Payment Method], you authorize Petrichor
                    Holdings (Cycle.io) to{" "}
                    {methodType === "us_bank_acct"
                        ? "debit the bank account"
                        : "charge the credit card"}{" "}
                    specified above for any amount owed for charges arising from
                    your use of Petrichor Holdings' (Cycle.io) services and/or
                    purchase of products from Petrichor Holdings (Cycle.io),
                    pursuant to Petrichor Holdings' (Cycle.io) website and
                    terms, until this authorization is revoked. You may amend or
                    cancel this authorization at any time by providing notice to
                    Petrichor Holdings (Cycle.io) with 30 (thirty) days notice.
                    You will be billed for this hub on the{" "}
                    {`${getOrdinalNum(renewalDay)}`} of every month, or the last
                    day of the month on months without that day.
                </div>
            ) : null}

            <DialogFooter className="flex justify-between">
                {footerButtons}
                <div>
                    <RhfGlobalFormError />
                </div>
                <div className="flex gap-4">
                    <AccessControlOverlay capability={"billing-methods-manage"}>
                        <LoaderButton
                            flavor="primary"
                            icon={faEdit}
                            type="button"
                            onClick={() => {
                                // needs to clear errors manually because the handleSubmit for this form will render
                                // errors to a slightly different route than the fields are registered to.  This will
                                // prevent automatic revalidation
                                form.clearErrors();
                                handleSubmit(onSubmit)();
                            }}
                            isLoading={isSubmitting}
                            disabled={!isDirty || !hub}
                        >
                            Create Payment Method
                        </LoaderButton>
                    </AccessControlOverlay>
                </div>
            </DialogFooter>
        </RhfFormProvider>
    );
}
