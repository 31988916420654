import {
    Checkbox,
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import {
    faCircle,
    faDirections,
    faPersonDolly,
    faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import {
    Button,
    ButtonProps,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    Container,
    Environment,
    Instance,
    Server,
    useCreateInstanceJobMutation,
    useGetCompatibleServersQuery,
} from "~/services/cycle";
import { useJobTracker } from "~/modules/jobs/hooks";
import { isZeroTime, zeroTimeString } from "@cycleplatform/core/util/time";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import {
    InfoPanel,
    PanelContent,
    PanelFooter,
} from "@cycleplatform/ui/components/panels";
import { UsableServerSelect } from "~/components/infrastructure/UsableServerSelect";
import { AccessControlOverlay } from "~/components/common/buttons";
import { containerMigrateAccessFn } from "@cycleplatform/core/modules/containers/acls";
import { isHypervisorContainer } from "@cycleplatform/core/modules/containers/util";
import classNames from "classnames";

type MigratePanelButtonProps = {
    className?: string;
    flavor?: ButtonProps["flavor"];
    instance: Instance;
    container: Container | undefined;
    environment: Environment | undefined;
};

export function MigratePanelButton({
    className,
    flavor,
    instance,
    container,
    environment,
}: MigratePanelButtonProps) {
    // TODO - update instance when this task received
    const isMigrating =
        instance.state.current === "migrating" ||
        (instance.migration &&
            isZeroTime(instance.migration.completed || zeroTimeString));

    const { data: servers, error } = useGetCompatibleServersQuery({
        containerId: instance.container_id,
    });

    if (error) {
        throw error;
    }

    return (
        <SectionDisabledControl>
            <AccessControlOverlay
                aclResource={environment}
                verifyFn={
                    container && instance
                        ? containerMigrateAccessFn(container, instance)
                        : () => undefined
                }
            >
                <PositionedMenu
                    placement="right-end"
                    className={"w-[30rem]"}
                    render={(_, setIsOpen) => (
                        <MigrateInstancePanel
                            servers={servers?.data}
                            container={container}
                            instance={instance}
                            setIsOpen={setIsOpen}
                        />
                    )}
                >
                    <Tooltip message="Migrate Instance" className="relative">
                        <Button
                            icon={faDirections}
                            flavor={flavor}
                            className={classNames(
                                className,
                                "w-10 !px-2 text-xs"
                            )}
                        />
                        {isMigrating && (
                            <FontAwesomeIcon
                                icon={faCircle}
                                className="text-warning absolute top-0 right-1 w-1 animate-pulse"
                            />
                        )}
                    </Tooltip>
                </PositionedMenu>
            </AccessControlOverlay>
        </SectionDisabledControl>
    );
}

export function MigrateInstancePanel({
    servers,
    container,
    instance,
    setIsOpen,
}: {
    servers?: Server[];
    container?: Container;
    instance: Instance;
    setIsOpen?: (isOpen: boolean) => void;
}) {
    const availableServers =
        servers?.filter((s) => s.id !== instance.server_id) || [];

    const isLb = instance.service === "loadbalancer";
    const isVm = isHypervisorContainer(container);

    return (
        <>
            <div className="pb-4 text-lg">
                <FontAwesomeIcon icon={faPersonDolly} /> Migrate Instance
            </div>

            {!isLb ? (
                <>
                    <p className="mb-4">
                        Migrate this {isVm ? "virtual machine" : "container"}{" "}
                        instance to another compatible server.
                    </p>
                    {availableServers.length ? (
                        <MigrateInstanceForm
                            container={container}
                            instance={instance}
                            setIsOpen={setIsOpen}
                        />
                    ) : (
                        <div className="mt-16 flex flex-col items-center justify-center gap-4">
                            <h2>
                                No available servers to migrate this instance
                                to.
                            </h2>
                        </div>
                    )}
                </>
            ) : (
                <InfoPanel type="info">
                    Load balancer instances cannot be migrated. Instead, create
                    a new instance, allow it to come online, and then remove the
                    "old" instance.
                </InfoPanel>
            )}
        </>
    );
}

export type MigrateInstanceSubmitType = {
    destination_server_id: string;
    stateful?: {
        copy_volumes: boolean;
    };
};

export function MigrateInstanceForm({
    container,
    instance,
    setIsOpen,
}: {
    container?: Container;
    instance: Instance;
    setIsOpen?: (isOpen: boolean) => void;
}) {
    const hasVolumes = container?.volumes && container.volumes.length > 0;
    const isStatefulCont = container && container.stateful;

    const form = useForm<MigrateInstanceSubmitType>({
        defaultValues: {
            stateful: isStatefulCont
                ? {
                      copy_volumes: hasVolumes,
                  }
                : undefined,
        },
        ...rhfConfig,
    });
    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { isDirty, isSubmitting },
    } = form;

    const [createInstanceJob] = useCreateInstanceJobMutation({
        fixedCacheKey: `migrate-instance-${instance.id}`,
    });

    const [trackJob] = useJobTracker();

    const onSubmit = (values: MigrateInstanceSubmitType) => {
        if (!isDirty) {
            return;
        }
        return trackJob(
            createInstanceJob({
                containerId: instance.container_id,
                instanceId: instance.id,
                body: {
                    action: "migration.start",
                    contents: values,
                },
            }).unwrap()
        ).then(() => {
            form.reset((formValues) => ({ ...formValues }));
            setIsOpen?.(false);
        }, handleSubmitError(form.setError));
    };
    const copyVolumes = watch("stateful.copy_volumes");
    const copyVolumesWarningVisible = isStatefulCont && !copyVolumes;

    const isVm = isHypervisorContainer(container);

    return (
        <RhfFormProvider {...form}>
            {isStatefulCont ? (
                <InfoPanel type="warning">
                    This will shut down this instance during the entire
                    migration period, which could take several minutes.
                </InfoPanel>
            ) : null}
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <RhfFormField
                    label="Server"
                    name="destination_server_id"
                    required
                    help={
                        isVm
                            ? "Virtual machines can currently only be migrated between servers in the same region."
                            : undefined
                    }
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <UsableServerSelect
                                {...field}
                                containerId={container?.id || ""}
                                filterServers={(s) =>
                                    s.id !== instance.server_id && !s.evacuate
                                }
                                filterFields={["id", "hostname"]}
                            />
                        )}
                        rules={{ ...required() }}
                        control={control}
                        name="destination_server_id"
                    />
                </RhfFormField>
                {hasVolumes ? (
                    <RhfFormField
                        label="Migrate Volumes"
                        name="copy_volumes"
                        help={`Migrate contents of volumes to new server${
                            isVm ? " - required for virtual machines." : "."
                        }`}
                    >
                        <div>
                            {!isVm ? (
                                <Checkbox
                                    {...register("stateful.copy_volumes")}
                                />
                            ) : (
                                // for VMs, copy_volumes is always true
                                // however disabled checkboxes do not submit in a form
                                // this lets us have it both ways.
                                <>
                                    <Checkbox
                                        defaultChecked={isVm}
                                        disabled={isVm}
                                    />
                                    <input
                                        type="hidden"
                                        {...register("stateful.copy_volumes")}
                                    />
                                </>
                            )}
                        </div>
                    </RhfFormField>
                ) : null}

                {copyVolumesWarningVisible ? (
                    <PanelContent className="border-error">
                        <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="text-error inline"
                            />
                            <h3>Confirm Do Not Copy Volumes</h3>
                        </div>
                        <p className="py-4 text-sm">
                            This action cannot be undone and all the contents of
                            the volume will be deleted once migration begins
                        </p>
                        <div className="flex w-full justify-between">
                            <Button
                                flavor="primary"
                                onClick={() =>
                                    setValue("stateful.copy_volumes", true)
                                }
                            >
                                No, Copy Volumes
                            </Button>
                            <PushAndHoldButton
                                flavor="discard"
                                type="button"
                                icon={faPersonDolly}
                                onClick={handleSubmit(onSubmit)}
                                isLoading={isSubmitting}
                                disabled={!isDirty}
                                tooltip="Hold to press. This will immediately migrate the instance"
                            >
                                Yes I&apos;m Sure
                            </PushAndHoldButton>
                        </div>
                    </PanelContent>
                ) : null}
                <PanelFooter>
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <PushAndHoldButton
                        type="button"
                        flavor="primary"
                        icon={faPersonDolly}
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isSubmitting}
                        disabled={!isDirty || copyVolumesWarningVisible}
                    >
                        Migrate Instance
                    </PushAndHoldButton>
                </PanelFooter>
            </form>
        </RhfFormProvider>
    );
}
