import {
    CopyInput,
    FormField,
    FormFieldset,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import {
    Container,
    Environment,
    Instance,
    Server,
    useGetInstanceVolumesQuery,
} from "~/services/cycle";
import { InstanceConsole } from "./InstanceConsole";
import { MigratePanelButton } from "./MigratePanelButton";
import { SshPanel } from "./SshPanel";
import { VolumeTableRow } from "./VolumeTableRow";
import { InstanceCharts } from "./InstanceCharts";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { Link } from "react-router-dom";
import { MigratingPanel } from "./MigratingPanel";
import { DialogPageHeader } from "@cycleplatform/ui/components/dialog";
import { NavIcons } from "~/components/layout/NavIcons";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { InstanceStateBadge } from "@cycleplatform/ui/components/instances/InstanceStateBadge";
import { InstanceTabParent } from "./InstanceTab";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";

type InstanceContentProps = {
    container: Container;
    environment: Environment | undefined;
    instance: Instance;
    server?: Server;
    parent: InstanceTabParent;
};

export function InstanceContent({
    instance,
    container,
    environment,
    server,
}: InstanceContentProps) {
    const { data: volumes, error } = useGetInstanceVolumesQuery({
        instanceId: instance.id,
        containerId: instance.container_id,
    });

    const hasVolumes = container.volumes && container.volumes?.length > 0;
    if (error) {
        throw error;
    }

    return (
        <div className="bg-cycle-white-accent dark:bg-cycle-black-accent w-full p-4 ">
            <DialogPageHeader
                title={instance.hostname}
                badge={<InstanceStateBadge instance={instance} />}
                icon={NavIcons["instances"]}
            >
                <div className="flex items-center gap-2">
                    <SshPanel
                        instance={instance}
                        container={container}
                        environment={environment}
                    />
                    <MigratePanelButton
                        instance={instance}
                        container={container}
                        environment={environment}
                    />
                </div>
            </DialogPageHeader>

            <div className="flex flex-wrap [&>*]:mb-4">
                {instance?.state?.current === "migrating" ? (
                    <MigratingPanel instance={instance} />
                ) : null}
                <DetailsPanel server={server} instance={instance} />

                <InstanceConsole instance={instance} />

                <Panel>
                    <PanelTitle title="Attached Volumes" />
                    <PanelContentBoundary stretch>
                        {hasVolumes ? (
                            <StyledDataTable>
                                {volumes?.data?.map((v) => (
                                    <VolumeTableRow
                                        key={v.id}
                                        volume={v}
                                        environment={environment}
                                        containerId={container?.id}
                                        instanceId={instance?.id}
                                    />

                                ))}
                            </StyledDataTable>
                        ) : (
                            <div className="p-4">
                                This instance does not have any connected
                                volumes.
                            </div>
                        )}
                    </PanelContentBoundary>
                </Panel>
                <InstanceCharts instance={instance} container={container} />
            </div>
        </div>
    );
}

function DetailsPanel({
    server,
    instance,
}: {
    server?: Server;
    instance: Instance;
}) {
    return (
        <Panel>
            <PanelTitle title="Details" />
            <PanelContentBoundary>
                <FormFieldset>
                    <InputRow className="flex gap-4">
                        <FormField className="w-full" label="ID">
                            <CopyInput value={instance.id} />
                        </FormField>
                        <div className="flex w-full items-center gap-4">
                            <FormField
                                className="w-full"
                                label="Server Hostname"
                            >
                                <CopyInput value={server?.hostname} />
                            </FormField>
                            <Link to={`/infrastructure/servers/${server?.id}`}>
                                <Tooltip message="Go to server dashboard">
                                    <FontAwesomeIcon
                                        icon={faServer}
                                        className="text-cycle-blue pt-4"
                                    />
                                </Tooltip>
                            </Link>
                        </div>
                        <a href={""} />
                    </InputRow>

                    <InputRow className="flex gap-4">
                        <FormField className="w-full" label="IPv6">
                            <CopyInput value={instance.environment.ipv6?.ip} />
                        </FormField>
                        <FormField className="w-full" label="IPv4">
                            <CopyInput
                                value={
                                    instance.environment.legacy?.ipv4?.ip ||
                                    "Legacy Mode Disabled"
                                }
                            />
                        </FormField>
                        <FormField className="w-full" label="MAC">
                            <CopyInput value={instance.environment.mac_addr} />
                        </FormField>
                    </InputRow>
                </FormFieldset>
            </PanelContentBoundary>
        </Panel>
    );
}
