import {
    faPlay,
    faPlus,
    faTrash,
    faSync,
    faArrowCircleUp,
    faStop,
    faQuestionCircle,
    faTerminal,
    faLocationDot,
    faLocationDotSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import {
    Activity,
    ComponentIncludes,
    Environment,
    VirtualMachine,
    VirtualMachineSshKey,
} from "~/services/cycle";
import { isErroredActivity } from "./common/util";

export function formatVmMessage(item: Activity, components: ComponentIncludes) {
    const { virtual_machine_id: vmId = "" } = item.context;
    const vmLink = (
        <Link
            to={generateDialogLink("virtual-machine", {
                "dialog-id": vmId,
            })}
        >
            {extractVmName(vmId, components)}
        </Link>
    );

    // create environment link
    const envName = extractEnvironmentName(
        item.context.environment_id || "",
        components
    );

    const envLink = (
        <Link to={`/environments/${item.context.environment_id}`}>
            {envName}
        </Link>
    );

    // link to env settings for SSH key
    const sshKeyLink = (
        <Link to={`/environments/${item.context.environment_id}/settings`}>
            {extractSshKeyName(item.component?.id, components)}
        </Link>
    );

    switch (item.event) {
        case "virtual-machine.initialize":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Initialized virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error creating virtual machine</span>
                ),
                icon: faPlus,
            };

        case "virtual-machine.task.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested start of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting start of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faPlay,
            };
        case "virtual-machine.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Started virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error starting virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faPlay,
            };

        case "virtual-machine.task.stop":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested stop of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting stop of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faStop,
            };

        case "virtual-machine.stop":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Stopped virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error stopping virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faStop,
            };

        case "virtual-machine.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faSync,
            };

        case "virtual-machine.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error creating virtual machine</span>
                ),
                icon: faPlus,
            };

        case "virtual-machine.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error updating virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faArrowCircleUp,
            };

        case "virtual-machine.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of virtual machine {vmLink} from
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of virtual machine {vmLink}{" "}
                        from environment {envLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "virtual-machine.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted virtual machine {vmLink} from environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error deleting virtual machine {vmLink}</span>
                ),
                icon: faTrash,
            };

        case "virtual-machine.sos.login": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Connected via serial-over-SSH to virtual machine{" "}
                        {vmLink}
                    </span>
                ) : (
                    <span>
                        Error connecting via serial-over-SSH to virtual machine{" "}
                        {vmLink}
                    </span>
                ),
                icon: faTerminal,
            };
        }
        // SSH Keys
        case "virtual-machine.ssh-key.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created virtual machine SSH key {sshKeyLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error creating virtual machine SSH key in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faPlus,
            };
        case "virtual-machine.ssh-key.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated virtual machine SSH key {sshKeyLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error updating virtual machine SSH key {sshKeyLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faArrowCircleUp,
            };

        case "virtual-machine.ssh-key.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of virtual machine SSH key{" "}
                        {sshKeyLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of virtual machine SSH key{" "}
                        {sshKeyLink} in environment {envLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "virtual-machine.ssh-key.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted virtual machine SSH key {sshKeyLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error deleting virtual machine SSH key {sshKeyLink}
                    </span>
                ),
                icon: faTrash,
            };

        // IP allocations
        case "virtual-machine.task.ip.allocate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested allocation of public IP address for virtual
                        machine {vmLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting allocation of public IP address for
                        virtual machine {vmLink} in environment {envLink}
                    </span>
                ),
                icon: faLocationDot,
            };
        case "virtual-machine.ip.allocate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Allocated public IP address to virtual machine{" "}
                        {vmLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting allocation of public IP address for
                        virtual machine {vmLink} in environment {envLink}
                    </span>
                ),
                icon: faLocationDot,
            };
        case "virtual-machine.task.ip.unallocate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested removal of public IP address from virtual
                        machine {vmLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting removal of public IP address from
                        virtual machine {vmLink} in environment {envLink}
                    </span>
                ),
                icon: faLocationDotSlash,
            };
        case "virtual-machine.ip.unallocate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Unallocated public IP address from virtual machine{" "}
                        {vmLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error unallocating opublic IP address from
                        virtual machine {sshKeyLink} in environment {envLink}
                    </span>
                ),
                icon: faLocationDot,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractVmName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as VirtualMachine).name;
}

function extractSshKeyName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as VirtualMachineSshKey).name;
}

function extractEnvironmentName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as Environment).name;
}
