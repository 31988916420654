import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { useContext } from "react";
import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import {
    LbUrlQueryTelem,
    getLbUrlsQuery,
    getUrlHostnamesQuery,
    getUrlResponsesQuery,
} from "./query";
import { LoadBalancerDialogContext } from "../../../../context";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";
import { UrlDestinationRow } from "./UrlDestinationRow";
import { UrlsTableHeader } from "./UrlsTableHeader";
import { UrlFilters } from "./UrlFilters";
import { useFiltering } from "@cycleplatform/ui/components/forms/filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { getHumanizedTime } from "@cycleplatform/core/util";
import classNames from "classnames";

export function UrlsSection({ port }: { port: number }) {
    const { environment } = useContext(LoadBalancerDialogContext);

    // FILTERING
    const filtering = useFiltering();
    const { accessExclusiveValue, isReady, getFilter } = filtering;

    const range = accessExclusiveValue({
        key: "range",
        isNumber: true,
    }) as number | undefined;

    const responses = getFilter("responses");

    const hostname = accessExclusiveValue({
        key: "hostname",
    }) as string | undefined;

    const currentRange = range || 24;

    // SHARED
    const sharedAggregateInput = {
        criteria: {
            "labels.environment_id": environment?.id || "",
            "labels.port": `${port}`,
        },
        range: currentRange,
    };

    // URL TELEMETRY DATA
    const {
        data: t,
        currentData: tCurrentData,
        isLoading,
        refetch,
    } = useGenerateAggregatedMetricsQuery(
        getLbUrlsQuery({
            ...sharedAggregateInput,
            responses: responses
                ? responses?.value?.map?.((r) => r.value)
                : null,
            hostname,
        }),
        {
            skip: !environment?.id || !isReady,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );

    const telemetry = t?.data as LbUrlQueryTelem | undefined;

    // AVAILABLE RESPONSES
    const { data: ar } = useGenerateAggregatedMetricsQuery(
        getUrlResponsesQuery({
            ...sharedAggregateInput,
        }),
        {
            skip: !environment?.id || !isReady,
        }
    );

    const availableResponses = ar?.data[0] as
        | { responses: string[] }
        | undefined;

    // AVAILABLE HOSTNAMES
    const { data: ah } = useGenerateAggregatedMetricsQuery(
        getUrlHostnamesQuery({
            ...sharedAggregateInput,
        }),
        {
            skip: !environment?.id || !isReady,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );

    const availableHostnames = ah?.data[0] as
        | { hostnames: string[] }
        | undefined;

    const renderFetching = !tCurrentData || !isReady;

    return (
        <Panel className="w-full">
            <PanelTitle
                className="flex items-center justify-between"
                title={
                    <div className="flex w-full items-center gap-2">
                        <p>Destinations</p>

                        <Tooltip
                            message={`last updated ${getHumanizedTime(
                                telemetry?.[0]?.time_fetched || ""
                            )}`}
                        >
                            <button
                                className={classNames(
                                    "border-cycle-gray/30 dark:border-cycle-white/10 rounded-md border p-1 px-2 text-xs",
                                    "flex items-center gap-2",
                                    "text-cycle-black dark:bg-cycle-black bg-white dark:text-white ",
                                    "h-[24px] items-center "
                                )}
                                onClick={() => refetch()}
                                disabled={renderFetching}
                            >
                                <FontAwesomeIcon
                                    icon={faRefresh}
                                    size="sm"
                                    className={classNames(
                                        renderFetching && "animate-spin",
                                        "text-cycle-blue"
                                    )}
                                />
                            </button>
                        </Tooltip>
                    </div>
                }
            >
                <div className="flex items-center gap-2  pl-2 font-normal">
                    <UrlFilters
                        filtering={filtering}
                        availableResponses={availableResponses?.responses}
                        availableHostnames={availableHostnames?.hostnames}
                    />
                </div>
            </PanelTitle>

            <PanelContent stretch>
                {!telemetry || isLoading ? (
                    <SkeletonTable />
                ) : (
                    <StyledDataTable>
                        <UrlsTableHeader />
                        <tbody>
                            {telemetry?.length ? (
                                telemetry.map((metric) => (
                                    <UrlDestinationRow
                                        destinationMetric={metric}
                                    />
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledCell colSpan={5}>
                                        <EmptyResource
                                            icon={NavIcons["telemetry"]}
                                            title="No Metrics"
                                            className="w-full border-none"
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            )}
                        </tbody>
                    </StyledDataTable>
                )}
            </PanelContent>
        </Panel>
    );
}
