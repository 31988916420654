import { ComponentType, ElementType, useEffect, useRef, useState } from "react";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    Placement,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    arrow,
    FloatingArrow,
    useClick,
} from "@floating-ui/react";
import classNames from "classnames";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PositionedMenuProps = {
    className?: string;
    closeClassName?: string;
    children: React.ReactNode;
    render: (
        isOpen: boolean,
        setIsOpen: (open: boolean) => void
    ) => React.ReactNode;
    placement?: Placement;
    as?: ComponentType | ElementType;
    hideCloseButton?: boolean;
    stretch?: boolean;
    onClose?: () => void;
};

export function PositionedMenu({
    children,
    render,
    className,
    closeClassName,
    placement = "top",
    as: Component = "div",
    hideCloseButton,
    stretch,
    onClose,
}: PositionedMenuProps) {
    const [hasOpened, setHasOpened] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const arrowRef = useRef(null);

    const { x, y, strategy, refs, context } = useFloating({
        open: isOpen,
        placement: placement,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            flip(),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "menu" });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        focus,
        dismiss,
        role,
    ]);

    useEffect(() => {
        if (isOpen === false && hasOpened) {
            onClose?.();
        } else {
            setHasOpened(true);
        }
    }, [isOpen]);

    return (
        <>
            <Component ref={refs.setReference} {...getReferenceProps()}>
                {children}
            </Component>

            <div
                ref={refs.setFloating}
                style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                }}
                className={classNames(
                    className,
                    "relative",
                    "dark:bg-cycle-gray dark:text-cycle-white animate-fade-in-fast dark: z-50 rounded-lg bg-white stroke-1 shadow-lg",
                    stretch ? "p-0" : "p-4",
                    !isOpen && "hidden"
                )}
                {...getFloatingProps()}
            >
                <div className="w-full">
                    <FloatingArrow
                        className="dark:fill-cycle-gray fill-white shadow-lg"
                        ref={arrowRef}
                        context={context}
                    />
                    {render(isOpen, setIsOpen)}
                </div>

                {!hideCloseButton && (
                    <button
                        onClick={() => setIsOpen(false)}
                        type="button"
                        className={classNames(
                            closeClassName,
                            "  flex h-10 w-10 items-center  justify-center transition-colors",
                            "hover:text-error-accent",
                            "absolute top-2 right-2"
                        )}
                    >
                        <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
                    </button>
                )}
            </div>
        </>
    );
}
