import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    Checkbox,
    FormField,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
    FormSectionHeader,
    FormSection,
    hasNoSpaces,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import { handleSubmitError } from "~/components/forms/util";
import {
    BasicSelect,
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { InfoPanel, PanelFooter } from "@cycleplatform/ui/components/panels";
import { faCircleInfo, faEdit, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
    ContainerSelect,
    ContainerSelectProps,
} from "~/components/containers/ContainerSelect";
import { clearDialogParams } from "~/components/dialogs/helpers";
import { EnvironmentSelect } from "~/components/environments/EnvironmentSelect";
import {
    Container,
    DnsRecord,
    DnsRecordTypes,
    useCreateDnsZoneRecordMutation,
    useGetContainerQuery,
    useGetContainersQuery,
    useGetDnsZoneQuery,
    useGetEnvironmentDeploymentsQuery,
    useGetEnvironmentSummaryQuery,
    useGetUserSuppliedCertificateQuery,
    useGetUserSuppliedCertificatesQuery,
    useUpdateDnsZoneRecordMutation,
} from "~/services/cycle";
import { Tab } from "@headlessui/react";
import { OptionTab } from "@cycleplatform/ui/components/options";
import { NavIcons } from "~/components/layout/NavIcons";
import { useKeepFormCurrent } from "~/components/common/forms";
import { DeploymentTagSelect } from "~/components/environments/deployments";
import { filterDuplicates } from "@cycleplatform/core/util";
import { FeatureLimited } from "~/components/sales";
import { useTierFeature } from "~/modules/hubs/permissions/useTierFeature";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { $error } from "@cycleplatform/core/util/log";
import { useGetCurrentTier } from "~/modules/settings";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";

type DnsRecordFormProps = {
    record?: DnsRecord;
    zoneId: string | null;
};

type FormValues = {
    zoneId?: string;
    recordId?: string;
    body: {
        name?: string;
        type: DnsRecordTypes;
    };
};
function getDefaultValues(
    record: DnsRecord | undefined,
    zoneId: string | null
) {
    return JSON.parse(
        JSON.stringify({
            zoneId: zoneId || "",
            recordId: record?.id || "",
            body: {
                name: record?.name || "",
                type: record?.type || { linked: undefined },
            },
        })
    );
}
export function DnsRecordForm({ record, zoneId }: DnsRecordFormProps) {
    const isEdit = !!record;
    const [typeStr, setTypeStr] = useState(
        record?.type ? Object.keys(record?.type)[0] : ""
    );

    const form = useForm<FormValues>({
        defaultValues: getDefaultValues(record, zoneId),
    });

    useKeepFormCurrent(form, record, (r) => getDefaultValues(r, zoneId));

    const {
        watch,
        register,
        setValue,
        formState: { isSubmitting, isDirty },
    } = form;

    const type = watch("body.type");

    const [updateDnsRecord] = useUpdateDnsZoneRecordMutation();
    const [createDnsRecord] = useCreateDnsZoneRecordMutation();
    const nav = useNavigate();

    const onSubmit = (data: FormValues) => {
        if (!isDirty) {
            return;
        }

        if (isEdit) {
            return updateDnsRecord({
                zoneId: zoneId || "",
                recordId: record?.id || "",
                body: {
                    type: data.body.type,
                },
            })
                .unwrap()
                .then(
                    () => nav(clearDialogParams()),
                    handleSubmitError(form.setError)
                );
        }

        if ("name" in data.body) {
            return createDnsRecord({
                zoneId: zoneId || "",
                body: {
                    name: data.body.name || "",
                    type: data.body.type,
                },
            })
                .unwrap()
                .then(
                    () => nav(clearDialogParams()),
                    handleSubmitError(form.setError)
                );
        }
    };

    useEffect(() => {
        // initialize default values for create
        if (isEdit) {
            return;
        }
        switch (typeStr) {
            case "a":
                setValue(
                    "body.type",
                    {
                        a: {
                            ip: "",
                        },
                    },
                    { shouldDirty: true }
                );
                return;
            case "aaaa":
                return setValue(
                    "body.type",
                    {
                        aaaa: {
                            ip: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "cname":
                return setValue(
                    "body.type",
                    {
                        cname: {
                            domain: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "ns":
                return setValue(
                    "body.type",
                    {
                        ns: {
                            domain: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "mx":
                return setValue(
                    "body.type",
                    {
                        mx: {
                            domain: "",
                            priority: 0,
                        },
                    },
                    { shouldDirty: true }
                );
            case "txt":
                return setValue(
                    "body.type",
                    {
                        txt: {
                            value: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "alias":
                return setValue(
                    "body.type",
                    {
                        alias: {
                            domain: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "srv":
                return setValue(
                    "body.type",
                    {
                        srv: {
                            weight: 0,
                            priority: 0,
                            port: 0,
                            domain: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "caa":
                return setValue(
                    "body.type",
                    {
                        caa: {
                            tag: "",
                            value: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "linked":
                return setValue(
                    "body.type",
                    {
                        linked: {
                            features: {
                                tls: {
                                    enable: false,
                                },
                                geodns: {
                                    enable: false,
                                },
                            },
                        },
                    },
                    { shouldDirty: true }
                );
        }
    }, [typeStr]);

    return (
        <RhfFormProvider {...form} onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex h-full flex-col justify-between">
                <div>
                    <FormSectionHeader header="General" />
                    <FormSection>
                        <RhfFormField label="record type" name="body.type">
                            <BasicSelect
                                disabled={isEdit}
                                onChange={(v) => {
                                    if (v) {
                                        return setTypeStr(v);
                                    }
                                }}
                                value={typeStr}
                                options={[
                                    {
                                        label: "LINKED",
                                        value: "linked",
                                        detail: "Cycle DNS Record type that facilitates the association of domains with containers",
                                    },
                                    {
                                        label: "A",
                                        value: "a",
                                        detail: "Holds the IP address of a domain.",
                                    },
                                    {
                                        label: "AAAA",
                                        value: "aaaa",
                                        detail: "Contains the IPv6 address for a domain (as opposed to A records, which list the IPv4 address)",
                                    },
                                    {
                                        label: "CNAME",
                                        value: "cname",
                                        detail: "Forwards one domain or subdomain to another domain, does NOT provide an IP address",
                                    },
                                    {
                                        label: "NS",
                                        value: "ns",
                                        detail: "Stores the name server for a DNS entry",
                                    },
                                    {
                                        label: "MX",
                                        value: "mx",
                                        detail: "Directs mail to an email server",
                                    },
                                    {
                                        label: "TXT",
                                        value: "txt",
                                        detail: "Allows an admin to store text notes in the record. These records are often used for email security",
                                    },
                                    {
                                        label: "ALIAS",
                                        value: "alias",
                                        detail: "Points your domain name to a hostname instead of an IP address",
                                    },
                                    {
                                        label: "SRV",
                                        value: "srv",
                                        detail: "Specifies a port for specific services",
                                    },
                                    {
                                        label: "CAA",
                                        value: "caa",
                                        detail: "Allows domain owners state which certificate authorities can issue certificates for that domain",
                                    },
                                ]}
                            />
                        </RhfFormField>
                    </FormSection>
                    {type && (
                        <>
                            <FormSectionHeader header="Details" />

                            <FormSection>
                                {!isEdit ? (
                                    <RhfFormField name="body.name" label="name">
                                        <TextInput
                                            {...register("body.name", {
                                                setValueAs: (v) =>
                                                    v.toLowerCase()
                                                        ? v.toLowerCase()
                                                        : "",
                                                ...required(),
                                                validate: {
                                                    ...hasNoSpaces(),
                                                },
                                            })}
                                        />
                                    </RhfFormField>
                                ) : null}

                                {"linked" in type ? <LinkedForm /> : null}

                                {"a" in type ? (
                                    <>
                                        <RhfFormField
                                            label="ip"
                                            name="body.type.a.ip"
                                            required
                                        >
                                            <TextInput
                                                {...register("body.type.a.ip", {
                                                    ...required(),
                                                })}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"aaaa" in type ? (
                                    <>
                                        <RhfFormField
                                            label="ip"
                                            name="body.type.aaaa.ip"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.aaaa.ip",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"cname" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.cname.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.cname.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"ns" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.ns.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.ns.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"mx" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.mx.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.mx.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="priority"
                                            name="body.type.mx.priority"
                                            required
                                        >
                                            <TextInput
                                                type="number"
                                                {...register(
                                                    "body.type.mx.priority",
                                                    {
                                                        ...required(),
                                                        valueAsNumber: true,
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"txt" in type ? (
                                    <>
                                        <RhfFormField
                                            label="value"
                                            name="body.type.txt.value"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.txt.value",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"alias" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.alias.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.alias.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"srv" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.srv.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.srv.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="ip"
                                            name="body.type.srv.weight"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.srv.weight",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="priority"
                                            name="body.type.srv.priority"
                                            required
                                        >
                                            <TextInput
                                                type="number"
                                                {...register(
                                                    "body.type.srv.priority",
                                                    {
                                                        ...required(),
                                                        valueAsNumber: true,
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="port"
                                            name="body.type.srv.port"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.srv.port",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"caa" in type ? (
                                    <>
                                        <RhfFormField
                                            label="tag"
                                            name="body.type.caa.tag"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.caa.tag",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="value"
                                            name="body.type.caa.value"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.caa.value",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                            </FormSection>
                        </>
                    )}
                </div>

                <DialogFooter className="items-center">
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <LoaderButton
                        icon={isEdit ? faEdit : faPlus}
                        onClick={form.handleSubmit(onSubmit)}
                        flavor="primary"
                        isLoading={isSubmitting}
                        disabled={!isDirty}
                    >
                        {isEdit ? "Update" : "Create"}
                    </LoaderButton>
                </DialogFooter>
            </div>
        </RhfFormProvider>
    );
}

function LinkedForm() {
    const { tier } = useGetCurrentTier();
    const isGeoDnsSupported = tier?.advanced_features.geodns;

    const { register, control, unregister, setValue } =
        useFormContext<FormValues>();

    const hasDeploymentsFeat = useTierFeature("deployments");

    const selectedContainer = useWatch({
        name: "body.type.linked.container_id",
        control,
    });

    const deploymentEnvId = useWatch({
        name: "body.type.linked.deployment.environment_id",
        control,
    });

    const [environment, setEnvironment] = useState<string | undefined | null>(
        deploymentEnvId || undefined
    );

    const { data: container } = useGetContainerQuery(
        {
            containerId: selectedContainer || "",
        },
        { skip: !selectedContainer }
    );

    useEffect(() => {
        if (container?.data?.environment?.id) {
            setEnvironment(container?.data?.environment?.id);
        }
    }, [container?.data?.environment?.id]);

    const [selectedIndex, setSelectedIndex] = useState(deploymentEnvId ? 1 : 0);

    return (
        <>
            <InputRow>
                <FormField label="Environment">
                    <EnvironmentSelect
                        onChange={(v) => {
                            setEnvironment(v);
                            if (selectedContainer) {
                                setValue(
                                    "body.type.linked.container_id",
                                    undefined
                                );
                            }
                            if (deploymentEnvId) {
                                setValue("body.type.linked.deployment", {
                                    environment_id: v || "",
                                    match: {
                                        container: "",
                                        tag: null,
                                    },
                                });
                            }
                        }}
                        isNullable
                        value={environment}
                    />
                </FormField>
                <RhfFormField
                    label="TLS Enabled"
                    name="body.type.linked.features.tls.enable"
                    help="Cycle will generate or use user-supplied TLS certificates and install them into the load balancer."
                    className="!w-[10rem]"
                >
                    <Checkbox
                        {...register("body.type.linked.features.tls.enable")}
                    />
                </RhfFormField>

                <RhfFormField
                    label="Geo DNS"
                    name="body.type.linked.features.geodns.enable"
                    help={
                        isGeoDnsSupported
                            ? "Cycle will attempt to match inbound requests to the closest load balancer geographically."
                            : `Geo DNS is not supported on the ${tier?.name} tier`
                    }
                    className="!w-[10rem]"
                >
                    <Checkbox
                        disabled={!isGeoDnsSupported}
                        {...register("body.type.linked.features.geodns.enable")}
                    />
                </RhfFormField>
            </InputRow>

            <div className="my-4">
                <TlsNotification />
            </div>

            {environment ? (
                <>
                    <div className="mb-4">
                        <Tab.Group
                            selectedIndex={selectedIndex}
                            onChange={setSelectedIndex}
                        >
                            <Tab.List className="mb-4 flex gap-2">
                                <OptionTab
                                    title="Link to Container"
                                    description="Associate with a specific container."
                                    icon={NavIcons["containers"]}
                                    onClick={() => {
                                        setSelectedIndex(0);
                                    }}
                                />
                                <OptionTab
                                    title="Link to Deployment"
                                    disabled={
                                        !hasDeploymentsFeat && !deploymentEnvId
                                    }
                                    description={
                                        hasDeploymentsFeat ? (
                                            "Associate with a container identifier that can change with deployment state."
                                        ) : (
                                            <>
                                                <FontAwesomeIcon
                                                    className="text-cycle-blue mr-2"
                                                    icon={faCircleInfo}
                                                />
                                                <span>
                                                    <Link to="/hub/plans">
                                                        Upgrade your tier
                                                    </Link>{" "}
                                                    to enable zero-downtime
                                                    updates
                                                </span>
                                            </>
                                        )
                                    }
                                    icon={NavIcons["deploy"]}
                                    onClick={() => {
                                        setSelectedIndex(1);
                                        unregister(
                                            "body.type.linked.container_id"
                                        );
                                    }}
                                />
                            </Tab.List>
                            <Tab.Panel>
                                {selectedIndex === 0 ? (
                                    <LinkToContainer
                                        environmentId={environment}
                                        container={container?.data}
                                    />
                                ) : null}
                            </Tab.Panel>
                            <Tab.Panel>
                                {selectedIndex === 1 ? (
                                    <LinkToDeployment
                                        environmentId={environment}
                                    />
                                ) : null}
                            </Tab.Panel>
                        </Tab.Group>
                    </div>
                </>
            ) : null}
        </>
    );
}

function LinkToContainer({
    container,
    environmentId,
}: {
    container: Container | undefined;
    environmentId: string;
}) {
    const { control, unregister } = useFormContext<FormValues>();

    // On render, ensure that deployment is unregistered.
    // This is required due to the way env change is handled, it must clear out the fields
    // and you cannot clear fields and unregister the section at the same time
    useEffect(() => {
        setTimeout(() => {
            unregister("body.type.linked.deployment");
        }, 200);
    }, []);
    return (
        <div className="flex flex-col">
            <RhfFormField
                label="container"
                name="body.type.linked.container_id"
                required
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <ContainerSelectInjectScheduler
                            {...field}
                            value={field.value ? field.value : undefined}
                            environmentId={environmentId}
                        />
                    )}
                    rules={{ ...required() }}
                    shouldUnregister
                    control={control}
                    name="body.type.linked.container_id"
                />
            </RhfFormField>

            {container?.config?.network?.public === "disable" ? (
                <InfoPanel type="warning" className="mb-4">
                    The selected container is currently set to private. In order
                    to reach this container via the load balancer, it will need
                    to first be set to public.
                </InfoPanel>
            ) : null}
        </div>
    );
}

function LinkToDeployment({ environmentId }: { environmentId: string }) {
    return (
        <>
            <FeatureLimited
                feature="deployments"
                render={
                    <InfoPanel type="warning">
                        You no longer have access to deployments. This record
                        will still function, but you will be unable to change it
                        until you upgrade your tier.
                    </InfoPanel>
                }
            />
            <LinkedDeploymentForm environmentId={environmentId} />
        </>
    );
}

function ContainerSelectInjectScheduler({
    environmentId,
    ...props
}: ContainerSelectProps & { environmentId: string }) {
    const { data: envSummary, error: summaryError } =
        useGetEnvironmentSummaryQuery({
            environmentId: environmentId,
        });

    const { data: schedulerContainer, error: schedulerError } =
        useGetContainerQuery(
            {
                containerId:
                    envSummary?.data?.services?.scheduler?.container_id || "",
            },
            { skip: !envSummary?.data?.services?.scheduler?.container_id }
        );

    if (schedulerError) {
        $error("error fetching environment scheduler");
    }
    if (summaryError) {
        $error("error fetching environment summary");
    }

    return (
        <ContainerSelect
            {...props}
            filter={{
                environment: environmentId || "",
            }}
            additionalContainers={
                schedulerContainer?.data &&
                schedulerContainer?.data?.config.network.public === "enable"
                    ? [schedulerContainer?.data]
                    : []
            }
        />
    );
}
function LinkedDeploymentForm({ environmentId }: { environmentId: string }) {
    const { register, control, setValue } = useFormContext<FormValues>();

    const { data: deployments } = useGetEnvironmentDeploymentsQuery({
        environmentId: environmentId,
    });

    const { data: containers } = useGetContainersQuery({
        filter: {
            environment: environmentId,
        },
        page: {
            number: 1,
            size: 100,
        },
    });

    const containerIdentifiers = filterDuplicates(
        containers?.data?.map((c) => c.identifier) || []
    );

    useEffect(() => {
        setValue("body.type.linked.deployment.environment_id", environmentId);
    }, []);

    return (
        <div className="flex gap-4">
            <TextInput
                hidden
                {...register("body.type.linked.deployment.environment_id")}
            />
            <RhfFormField
                label="Container Identifier"
                name="body.type.linked.deployment.match.container"
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <SelectInput
                            {...field}
                            placeholder="Select a container identifier"
                            isCreatable
                            options={containerIdentifiers}
                            formatOption={(o) => <FormattedOption label={o} />}
                        />
                    )}
                    control={control}
                    name="body.type.linked.deployment.match.container"
                />
            </RhfFormField>

            <RhfFormField
                label="Deployment Tag"
                name="body.type.linked.deployment.match.tag"
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <DeploymentTagSelect
                            {...field}
                            isCreatable
                            value={field.value || undefined}
                            environmentId={environmentId}
                        />
                    )}
                    control={control}
                    name="body.type.linked.deployment.match.tag"
                />
            </RhfFormField>
        </div>
    );
}

function TlsNotification() {
    const { control, getValues } = useFormContext<FormValues>();
    const enabled = useWatch({
        control,
        name: "body.type.linked.features.tls.enable",
    });
    const domain = useWatch({ control, name: "body.name" });
    const { data: zone, error: zoneError } = useGetDnsZoneQuery({
        zoneId: getValues("zoneId") || "",
    });

    const [fqdn, setFqdn] = useState("");

    const {
        data: certs,
        error: certsError,
        isLoading,
    } = useGetUserSuppliedCertificatesQuery(
        {
            filter: {
                domain: fqdn,
            },
        },
        { skip: !zone || !enabled || !fqdn }
    );
    useEffect(() => {
        if (!zone) {
            return;
        }
        if (domain === "@") {
            setFqdn(zone.data.origin);
        } else {
            setFqdn(`${domain}.${zone.data.origin}`);
        }
    }, [domain, zone?.data.origin]);

    if (zoneError) {
        $error("error with tls notification", zoneError);
        return null;
    }
    if (certsError) {
        $error("error with tls notification", certsError);
        return null;
    }

    if (!enabled || isLoading) {
        return null;
    }

    if (certs?.data.filter((c) => c.state.current === "live").length) {
        return (
            <InfoPanel>
                This domain will use an existing user supplied TLS certificate.
            </InfoPanel>
        );
    }

    return (
        <InfoPanel>
            This domain will use a Cycle generated TLS certificate that will
            automatically renew every 60 days.
        </InfoPanel>
    );
}
