import { useCreateHubMutation } from "~/services/cycle";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "~/hooks";
import { cycleApi } from "~/services/cycle";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { setActiveHub } from "~/modules/hubs/slice";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { useNavigate } from "react-router-dom";
import { handleSubmitError } from "~/components/forms/util";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";
import { initializeForm } from "~/modules/forms/slice";
import { HUB_SETUP_KEY } from "~/components/dialogs/hubs/hubSetup";
import { PanelFooter } from "@cycleplatform/ui/components/panels";

type HubCreateFormType = {
    name: string;
};

export function HubCreateForm({ isDialog }: { isDialog?: boolean }) {
    const form = useForm<HubCreateFormType>({});
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = form;

    const [createHub] = useCreateHubMutation();
    const dispatch = useAppDispatch();

    const nav = useNavigate();

    const onHubCreate = (data: HubCreateFormType) => {
        if (!isDirty) {
            return;
        }
        return createHub({
            body: {
                name: data.name,
            },
        })
            .unwrap()
            .then((hub) => {
                dispatch(setActiveHub({ hubId: hub?.data?.id }));
            })
            .then(() => {
                dispatch(initializeForm({ key: HUB_SETUP_KEY }));
            })
            .then(() => {
                dispatch(
                    cycleApi.util.invalidateTags([
                        "Hub",
                        "Hub.Resource",
                        "Hub.Membership",
                        "Hub.Integration",
                        "Hub.Provider",
                        "Hub.Invite",
                        "Hub.Role",
                        "Hub.Notifications",
                        "PersonalAccount",
                        "PublicAccount",
                        "SearchIndex",
                    ])
                );
                nav({ pathname: "/", search: "" });
            })

            .catch(handleSubmitError(form.setError));
    };

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onHubCreate)}>
            <RhfFormField label="hub name" name="name" required>
                <TextInput {...register("name", { ...required() })} />
            </RhfFormField>

            <Footer isDialog={isDialog}>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onHubCreate)}
                    icon={faPlus}
                    flavor="primary"
                    disabled={!isDirty}
                >
                    Create
                </LoaderButton>
            </Footer>
        </RhfFormProvider>
    );
}

function Footer({
    children,
    isDialog,
}: {
    children: React.ReactNode;
    isDialog?: boolean;
}) {
    if (isDialog) {
        return <DialogFooter>{children}</DialogFooter>;
    } else {
        return <PanelFooter>{children}</PanelFooter>;
    }
}
