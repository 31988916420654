import { TagDescription } from "@reduxjs/toolkit/query";
import { AppDispatch, RootState } from "~/store";
import type { CycleApiTag } from "../tags";
import { cycleApi } from "../../__generated";
import { debouncedInvalidateTag } from "./util";
import {
    patchAllCacheEntryResourceStates,
    patchAllCacheEntryErrors,
    patchEnvironmentContainerStateCounts,
    patchContainerInstanceStateCounts,
} from "./patch";
import { pushNotification } from "~/modules/notifications/slice";
import {
    AccountNotificationMessage,
    HubNotificationMessage,
} from "@cycleplatform/core/notifications";
import { updateJobState } from "~/modules/jobs/slice";
import { router } from "~/routes/Routes";

/**
 * Dictates what happens when a particular topic is received on the websocket.
 * This usually involves invalidating tags.
 */
export async function notificationTopicToActions(
    message: HubNotificationMessage | AccountNotificationMessage,
    api: typeof cycleApi,
    getState: () => RootState,
    dispatch: AppDispatch
) {
    // Quick helper to reduce boilerplate
    const patchResourceState = (
        tags: Array<TagDescription<CycleApiTag>>,
        options?: Parameters<typeof patchAllCacheEntryResourceStates>[4]
    ) => {
        return patchAllCacheEntryResourceStates(
            tags,
            api,
            message,
            getState(),
            options
        ).map(dispatch);
    };

    // Quick helper to reduce boilerplate
    const patchErrorState = (tags: Array<TagDescription<CycleApiTag>>) => {
        return patchAllCacheEntryErrors(tags, api, message, getState()).map(
            dispatch
        );
    };

    switch (message.topic) {
        case "account.state.changed":
            if (message.object.state === "deleted") {
                // sorry, you've been deleted - bye!
                router.navigate("/logout");
            }
            return;

        // announcements
        case "announcement.created":
        case "announcement.state_changed":
        case "announcement.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Announcement",
                        id: "LIST",
                    },
                ])
            );

        // billing credits
        case "billing.credit.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Billing.Credit",
                        id: "LIST",
                    },
                ])
            );
        case "billing.credit.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Billing.Credit",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                { type: "Billing.Credit" as const, id: message.object.id },
            ]);
        case "billing.credit.error":
        case "billing.credit.error_reset":
            return patchErrorState([
                { type: "Billing.Credit" as const, id: message.object.id },
            ]);

        // billing discounts

        case "billing.discount.state.changed":
            return patchResourceState([
                { type: "Billing.Discount" as const, id: message.object.id },
            ]);

        case "billing.discount.error":
        case "billing.discount.error_reset":
            return patchErrorState([
                { type: "Billing.Discount" as const, id: message.object.id },
            ]);

        // billing invoices

        case "billing.invoice.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Billing.Invoice",
                        id: "LIST",
                    },
                ])
            );
        case "billing.invoice.updated":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Billing.Invoice", id: message.object.id },
                ])
            );
        case "billing.invoice.state.changed":
            // something probably changed on the invoice, like a payment
            // so we want to fully refresh the invoice
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Billing.Invoice",
                        id: "LIST",
                    },
                    { type: "Billing.Invoice", id: message.object.id },
                ])
            );

        case "billing.invoice.error":
        case "billing.invoice.error_reset":
            return patchErrorState([
                { type: "Billing.Invoice" as const, id: message.object.id },
            ]);

        // billing methods

        case "billing.method.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Billing.Method",
                        id: "LIST",
                    },
                ])
            );
        case "billing.method.updated":
            return dispatch(
                api.util.invalidateTags([
                    // neccessary to invalidate all tags because when you update primary, you only get the id of the
                    // new primary.  You need to invalidate all methods to ensure the "old" primary updates
                    // appropriately
                    { type: "Billing.Method", id: "LIST" },
                ])
            );
        case "billing.method.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Billing.Method",
                            id: "LIST",
                        },
                    ])
                );
            }
            return patchResourceState([
                { type: "Billing.Method" as const, id: message.object.id },
            ]);

        case "billing.method.error":
        case "billing.method.error_reset":
            return patchErrorState([
                { type: "Billing.Method" as const, id: message.object.id },
            ]);

        // billing orders

        case "billing.order.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Billing.Order",
                        id: "LIST",
                    },
                    { type: "Hub", id: message.context.hub_id || "" },
                ])
            );
        case "billing.order.updated":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Billing.Order", id: message.object.id },
                    { type: "Hub", id: message.context.hub_id || "" },
                ])
            );
        case "billing.order.state.changed":
            switch (message.object.state) {
                case "deleted":
                    dispatch(
                        api.util.invalidateTags([
                            {
                                type: "Billing.Order",
                                id: "LIST",
                            },
                        ])
                    );
                    break;
            }
            return patchResourceState([
                { type: "Billing.Order" as const, id: message.object.id },
            ]);

        case "billing.order.error":
        case "billing.order.error_reset":
            return patchErrorState([
                { type: "Billing.Order" as const, id: message.object.id },
                { type: "Hub", id: message.context.hub_id || "" },
            ]);

        //DNS

        case "dns.zone.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Dns.Zone",
                        id: "LIST",
                    },
                ])
            );

        case "dns.zone.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Dns.Zone" as const,
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                { type: "Dns.Zone" as const, id: message.object.id },
            ]);

        case "dns.zone.verified":
        case "dns.zone.reconfigured":
            dispatch(
                api.util.invalidateTags([
                    {
                        type: "Dns.Record",
                        id: "LIST",
                    },
                ])
            );
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Dns.Zone",
                        id: message.object.id,
                    },
                ])
            );

        case "dns.zone.error":
        case "dns.zone.error_reset":
            return patchErrorState([
                { type: "Dns.Zone" as const, id: message.object.id },
            ]);

        case "dns.zone.records.reconfigured":
        case "dns.zone.certificate.ready":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Dns.Record",
                        id: "LIST",
                    },
                ])
            );
        case "dns.zone.record.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Dns.Record" as const,
                            id: "LIST",
                        },
                    ])
                );
            }
            return patchResourceState([
                { type: "Dns.Record" as const, id: message.object.id },
            ]);

        // DNS - TLS
        case "dns.certificate.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Dns.Certificate",
                        id: "LIST",
                    },
                ])
            );
        case "dns.certificate.state.changed":
            return patchResourceState([
                { type: "Dns.Certificate" as const, id: message.object.id },
            ]);
        case "dns.certificate.error":
        case "dns.certificate.error_reset":
            return patchErrorState([
                { type: "Dns.Certificate" as const, id: message.object.id },
            ]);

        // images

        case "image.updated":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Image", id: message.object.id },
                ])
            );
        case "image.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Image",
                            id: "LIST",
                        },
                    ])
                );
            }
            dispatch(
                api.util.invalidateTags([
                    {
                        type: "Image.Source",
                        id: "LIST",
                    },
                ])
            );

            return patchResourceState([
                { type: "Image" as const, id: message.object.id },
            ]);
        case "image.created":
            dispatch(
                api.util.invalidateTags([
                    {
                        type: "Image",
                        id: "LIST",
                    },
                    {
                        type: "Image.Source",
                        id: "LIST",
                    },
                ])
            );
            return;
        case "image.source.updated":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Image.Source", id: message.object.id },
                ])
            );
        case "image.source.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Image.Source",
                            id: "LIST",
                        },
                    ])
                );
            }
            return patchResourceState([
                { type: "Image.Source" as const, id: message.object.id },
            ]);
        case "image.source.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Image.Source",
                        id: "LIST",
                    },
                ])
            );

        // billing services

        case "billing.service.state.changed":
            switch (message.object.state) {
                case "deleted":
                    dispatch(
                        api.util.invalidateTags([
                            {
                                type: "Billing.Service",
                                id: "LIST",
                            },
                        ])
                    );
                    break;
                case "active":
                    dispatch(
                        api.util.invalidateTags([
                            {
                                type: "Billing.Service",
                                id: "LIST",
                            },
                        ])
                    );
                    // Hubs have their own billing information stored on object that may change when
                    // the billing service state changes
                    dispatch(
                        api.util.invalidateTags([
                            { type: "Hub", id: message.context.hub_id || "" },
                        ])
                    );
            }
            // Inline state update
            return patchResourceState([
                { type: "Billing.Service" as const, id: message.object.id },
            ]);
        case "billing.service.error":
        case "billing.service.error_reset":
            return patchErrorState([
                { type: "Billing.Service" as const, id: message.object.id },
            ]);

        // containers
        case "container.created": {
            // Invalidate environment meta state counts to ensure new is included
            patchEnvironmentContainerStateCounts(getState(), message).map(
                dispatch
            );

            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Container",
                        id: "LIST",
                    },
                    {
                        type: "Environment.Deployments",
                        id: message.object.id,
                    },
                    ...(message.context.environments || [])
                        .map((id) => [
                            {
                                type: "Environment.Summary" as const,
                                id,
                            },
                            {
                                type: "Environment.Deployments" as const,
                                id,
                            },
                        ])
                        .flat(),
                ])
            );
        }
        case "container.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Container",
                        id: message.object.id,
                    },
                    ...(message.context.environments || []).map((id) => ({
                        type: "Environment.Summary" as const,
                        id,
                    })),
                ])
            );
        case "container.reconfigured":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Container",
                        id: message.object.id,
                    },
                    ...(message.context.environments || []).map((id) => ({
                        type: "Environment.Summary" as const,
                        id,
                    })),
                ])
            );
        case "container.state.changed": {
            if (
                message.object.state === "running" &&
                message.object.state_previous === "reimaging"
            ) {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Container",
                            id: message.object.id,
                        },
                    ])
                );
            }
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Container",
                            id: "LIST",
                        },
                        ...(message.context.environments || []).map((id) => ({
                            type: "Environment.Deployments" as const,
                            id,
                        })),
                    ])
                );
            }
            // Inline state update
            patchResourceState([
                { type: "Container" as const, id: message.object.id },
            ]);

            // Invalidate environment meta state counts
            patchEnvironmentContainerStateCounts(getState(), message).map(
                dispatch
            );

            // No matter what, we can never guarantee the container counts over the websocket will be accurate.
            // This is because multiple servers can have copies of containers that are starting, and therefore we may have
            // multiple state change events occur from compute. Instances, however, don't have this problem since
            // there is only one instance at any point.
            // Therefore, in order to ensure our container counts are correct, we debounce the update
            // so that at the end, we always make an extra call to whatever is holding those counts so they stay accurate.
            debouncedInvalidateTag(
                {
                    id: message.context.environments?.[0] || "",
                    type: "Environment.ContainerStateCounts",
                },
                dispatch
            );

            return dispatch(
                api.util.invalidateTags([
                    ...(message.context.environments || []).map((id) => ({
                        type: "Environment.Summary" as const,
                        id,
                    })),
                ])
            );
        }
        case "container.error":
        case "container.error_reset": {
            return dispatch(
                api.util.invalidateTags([
                    { type: "Container", id: message.object.id },
                ])
            );
        }

        case "container.backup.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Container.Backup",
                            id: "LIST",
                        },
                    ])
                );
            }
            return patchResourceState([
                { type: "Container.Backup" as const, id: message.object.id },
            ]);

        case "container.instance.migration.update":
        case "container.instances.reconfigured": {
            // Invalidate anything relying on instance counts so we refresh.
            // We have to reset essentially so our counts remain accurate.
            const invalidate = [
                ...(message.context.containers || []).map((c) => ({
                    // TODO maybe do telemetry here as well
                    type: "Container.InstanceStateCounts" as const,
                    id: c,
                })),
                // invalidate our environment summaries for instance charts as well
                ...(message.context.environments || []).map((e) => ({
                    type: "Environment.Summary" as const,
                    id: e,
                })),
                { type: "Container" as const, id: message.object.id },
                { type: "Instance" as const, id: "LIST" },
            ];

            // Need to refresh instances - may have more/less
            return dispatch(api.util.invalidateTags(invalidate));
        }

        case "container.instance.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Instance",
                            id: "LIST",
                        },
                    ])
                );
            }
            patchContainerInstanceStateCounts(getState(), message).map(
                dispatch
            );
            return patchResourceState([
                { type: "Instance" as const, id: message.object.id },
            ]);

        case "container.instance.health.status.changed":
            return patchResourceState([
                { type: "Instance" as const, id: message.object.id },
            ]);

        case "container.instance.error":
        case "container.instance.error_reset":
            // Invalidate anything relying on instance counts so we refresh.
            dispatch(
                api.util.invalidateTags(
                    (message.context.containers || []).map((c) => ({
                        type: "Container.InstanceTelemetry",
                        id: c,
                    }))
                )
            );
            return patchErrorState([
                { type: "Instance" as const, id: message.object.id },
            ]);

        // environments

        case "environment.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Environment",
                        id: "LIST",
                    },
                ])
            );
        case "environment.updated":
            // case "environment.started":
            // case "environment.stopped":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Environment",
                        id: message.object.id,
                    },
                    ...(message.context.environments || []).map((id) => ({
                        type: "Environment.Summary" as const,
                        id,
                    })),
                ])
            );
        case "environment.state.changed": {
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Environment",
                            id: "LIST",
                        },
                        {
                            type: "Environment.Deployments",
                            id: message.object.id,
                        },
                    ])
                );
            }
            // Inline state update
            return patchResourceState([
                { type: "Environment" as const, id: message.object.id },
            ]);
        }
        case "environment.error":
        case "environment.error_reset":
            return patchErrorState([
                { type: "Environment" as const, id: message.object.id },
            ]);

        case "environment.scoped-variable.updated":
        case "environment.scoped-variable.created":
        case "environment.scoped-variable.state.changed":
            // case "environment.scoped-variable.deleted":

            return dispatch(
                api.util.invalidateTags([
                    { type: "Environment.ScopedVariable", id: "LIST" },
                    {
                        type: "Environment.ScopedVariable",
                        id: message.object.id,
                    },
                ])
            );

        case "environment.deployments.reconfigured":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Environment", id: message.object.id },
                    { type: "Container", id: "LIST" },
                ])
            );

        case "environment.services.vpn.users.updated":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Environment.Vpn.User", id: "LIST" },
                ])
            );

        // TODO:  Waiting to hear from Jake on how best to invaldate LoadBalancer container on update
        // Load Balancer instances panel is constructed from the LbContainer.meta struct
        // need to invalidate the api call to update the ups on LB reconfiguration
        // case "environment.services.lb.ips.modified":
        //     return dispatch(api.util.invalidateTags(["Container"]));

        case "environment.services.lb.ips.modified":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Environment", id: message.object.id },
                ])
            );

        case "environment.services.reconfigured":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Environment", id: message.object.id },
                    { type: "Environment.Service", id: message.object.id },
                    { type: "Environment.Summary", id: message.object.id },
                ])
            );

        // events
        case "event.pushed":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Hub.Event", id: message.object.id },
                ])
            );

        // hubs

        case "hub.activity.new": {
            let contextTags: Array<TagDescription<CycleApiTag>> = [];
            // Ensure we dispatch invalidations for specific activity types
            if (message.context.containers) {
                contextTags = [
                    ...message.context.containers.map((c) => ({
                        type: "Container.Activity" as const,
                        id: c,
                    })),
                ];
            }

            if (message.context.environments) {
                contextTags = [
                    ...contextTags,
                    ...message.context.environments.map((e) => ({
                        type: "Environment.Activity" as const,
                        id: e,
                    })),
                ];
            }

            dispatch(
                api.util.invalidateTags([
                    "Hub.Activity",
                    "Hub.ApiKey",
                    { type: "Hub.ApiKey", id: "LIST" },
                    ...contextTags,
                ])
            );
            return;
        }

        case "hub.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Hub",
                        id: message.object.id,
                    },
                ])
            );

        case "hub.integration.created":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Hub.Integration", id: "LIST" },
                ])
            );

        case "hub.integration.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Hub.Integration",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Hub.Integration" as const,
                    id: message.object.id,
                },
            ]);

        case "hub.integration.error":
        case "hub.integration.error_reset":
            return patchErrorState([
                {
                    type: "Hub.Integration" as const,
                    id: message.object.id,
                },
            ]);

        // | "pipeline.run.created"
        case "pipeline.run.created":
            return dispatch(
                api.util.invalidateTags([{ type: "Pipeline.Run", id: "LIST" }])
            );

        case "pipeline.run.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Pipeline.Run",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Pipeline.Run" as const,
                    id: message.object.id,
                },
            ]);

        case "pipeline.run.error":
        case "pipeline.run.error_reset":
            return patchErrorState([
                {
                    type: "Pipeline.Run" as const,
                    id: message.object.id,
                },
            ]);

        case "pipeline.created":
            return dispatch(
                api.util.invalidateTags([{ type: "Pipeline", id: "LIST" }])
            );

        case "pipeline.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Pipeline",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Pipeline" as const,
                    id: message.object.id,
                },
            ]);

        case "pipeline.error":
        case "pipeline.error_reset":
            return patchErrorState([
                {
                    type: "Pipeline" as const,
                    id: message.object.id,
                },
            ]);

        case "pipeline.key.created": {
            return dispatch(
                api.util.invalidateTags([{ type: "Pipeline.Key", id: "LIST" }])
            );
        }

        case "pipeline.key.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Pipeline.Key",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Pipeline.Key" as const,
                    id: message.object.id,
                },
            ]);

        case "pipeline.key.error":
        case "pipeline.key.error_reset":
            return patchErrorState([
                {
                    type: "Pipeline.Key" as const,
                    id: message.object.id,
                },
            ]);

        case "pipeline.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Pipeline",
                        id: message.object.id,
                    },
                ])
            );
        case "pipeline.run.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Pipeline.Run",
                        id: message.object.id,
                    },
                ])
            );
        // infrastructure

        case "ips_pool.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Infrastructure.Ip",
                            id: "LIST",
                        },
                    ])
                );
            }
            return patchResourceState([
                {
                    type: "Infrastructure.Ip" as const,
                    id: message.object.id,
                },
            ]);

        case "ips_pool.error":
        case "ips_pool.error_reset":
            return patchErrorState([
                {
                    type: "Infrastructure.Ip" as const,
                    id: message.object.id,
                },
            ]);

        case "infrastructure.server.new": {
            dispatch(
                api.util.invalidateTags([
                    {
                        type: "Infrastructure.Cluster",
                        id: "LIST",
                    },
                ])
            );
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Infrastructure.Server",
                        id: "LIST",
                    },
                ])
            );
        }

        case "infrastructure.server.error":
        case "infrastructure.server.error_reset":
            return patchErrorState([
                {
                    type: "Infrastructure.Server" as const,
                    id: message.object.id,
                },
            ]);

        case "infrastructure.server.reconfigured":
        case "infrastructure.server.restart":
        case "infrastructure.server.evacuation.changed":
        case "infrastructure.server.evacuation.completed":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Infrastructure.Server",
                        id: message.object.id,
                    },
                ])
            );
        case "infrastructure.server.state.changed": {
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Infrastructure.Server",
                            id: "LIST",
                        },
                    ])
                );
            }
            return patchResourceState([
                {
                    type: "Infrastructure.Server" as const,
                    id: message.object.id,
                },
            ]);
        }

        case "infrastructure.provider.created":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Infrastructure.Provider", id: "LIST" },
                ])
            );

        case "infrastructure.provider.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Infrastructure.Provider",
                            id: "LIST",
                        },
                    ])
                );
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Infrastructure.Cluster",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Infrastructure.Provider" as const,
                    id: message.object.id,
                },
            ]);

        case "infrastructure.provider.error":
        case "infrastructure.provider.error_reset":
            return patchErrorState([
                {
                    type: "Infrastructure.Provider" as const,
                    id: message.object.id,
                },
            ]);

        case "infrastructure.cluster.new":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Infrastructure.Cluster", id: "LIST" },
                ])
            );

        case "infrastructure.cluster.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Infrastructure.Cluster",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Infrastructure.Cluster" as const,
                    id: message.object.id,
                },
            ]);

        case "infrastructure.cluster.error":
        case "infrastructure.cluster.error_reset":
            return patchErrorState([
                {
                    type: "Infrastructure.Cluster" as const,
                    id: message.object.id,
                },
            ]);

        case "infrastructure.autoscale.group.new":
            return dispatch(
                api.util.invalidateTags([
                    { type: "Infrastructure.AutoscaleGroup", id: "LIST" },
                ])
            );

        case "infrastructure.autoscale.group.updated":
        case "infrastructure.autoscale.group.reconfigured":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Infrastructure.AutoscaleGroup",
                        id: message.object.id,
                    },
                ])
            );

        case "infrastructure.autoscale.group.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Infrastructure.AutoscaleGroup",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Infrastructure.AutoscaleGroup" as const,
                    id: message.object.id,
                },
            ]);

        case "infrastructure.autoscale.group.error":
        case "infrastructure.autoscale.group.error_reset":
            return patchErrorState([
                {
                    type: "Infrastructure.AutoscaleGroup" as const,
                    id: message.object.id,
                },
            ]);
        // Networks

        case "sdn.network.created":
            return dispatch(
                api.util.invalidateTags([{ type: "Network.Sdn", id: "LIST" }])
            );

        case "sdn.network.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Network.Sdn",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Network.Sdn" as const,
                    id: message.object.id,
                },
            ]);

        case "sdn.network.error":
        case "sdn.network.error_reset":
            return patchErrorState([
                {
                    type: "Network.Sdn" as const,
                    id: message.object.id,
                },
            ]);

        //stacks
        case "stack.created":
            return dispatch(
                api.util.invalidateTags([{ type: "Stack", id: "LIST" }])
            );

        case "stack.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Stack",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Stack" as const,
                    id: message.object.id,
                },
            ]);
        case "stack.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Stack",
                        id: message.object.id,
                    },
                ])
            );
        case "stack.error":
        case "stack.error_reset":
            return patchErrorState([
                {
                    type: "Stack" as const,
                    id: message.object.id,
                },
            ]);

        case "stack.build.created":
            dispatch(
                api.util.invalidateTags([{ type: "Stack.Build", id: "LIST" }])
            );
            return;

        case "stack.build.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Stack.Build",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Stack.Build" as const,
                    id: message.object.id,
                },
            ]);
        case "stack.build.error":
        case "stack.build.error_reset":
            return patchErrorState([
                {
                    type: "Stack.Build" as const,
                    id: message.object.id,
                },
            ]);
        // jobs
        case "job.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Job",
                        id: "LIST",
                    },
                ])
            );

        case "job.state.changed": {
            if (message.object.state === "completed") {
                dispatch(
                    pushNotification({
                        title: "Job Complete",
                        message: message.context.label || "",
                        type: "info",
                        link: "/jobs",
                    })
                );
            }
            dispatch(
                updateJobState({
                    jobId: message.object.id,
                    state: message.object.state as "new",
                })
            );
            return patchResourceState(
                [{ type: "Job" as const, id: message.object.id }],
                {
                    shouldUpdate: (j) => {
                        // If state is completed, we DO NOT want to update it again.
                        return j.state.current !== "completed";
                    },
                }
            );
        }
        case "job.error":
            {
                dispatch(
                    pushNotification({
                        title: "Job Failed",
                        message: `${message.context.label} - ${
                            message.object.error || "unknown error"
                        }`,
                        type: "error",
                        link: "/jobs",
                    })
                );
            }
            dispatch(
                updateJobState({
                    jobId: message.object.id,
                    state: "error",
                    error: message.object.error,
                })
            );
            return patchResourceState([
                { type: "Job" as const, id: message.object.id },
            ]);

        // Hub
        case "hub.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Hub",
                        id: "LIST",
                    },
                ])
            );
        case "hub.state.changed":
            if (
                message.object.state === "deleted" ||
                message.object.state === "deleting"
            ) {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Hub",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Hub" as const,
                    id: message.object.id,
                },
            ]);
        // Hub Memberships
        case "hub.invite.new":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Hub.Invite",
                        id: "LIST",
                    },
                ])
            );
        case "hub.membership.new":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Hub.Membership",
                        id: "LIST",
                    },
                ])
            );
        case "hub.membership.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Hub.Membership",
                        id: "LIST",
                    },
                    {
                        type: "Hub",
                        id: "LIST",
                    },
                    {
                        type: "Hub.Invite",
                        id: "LIST",
                    },
                ])
            );
        case "hub.membership.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Hub.Membership",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Hub.Membership" as const,
                    id: message.object.id,
                },
            ]);
        case "hub.membership.error":
        case "hub.membership.error_reset":
            return patchErrorState([
                {
                    type: "Hub.Membership" as const,
                    id: message.object.id,
                },
            ]);

        case "hub.role.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Hub.Role",
                        id: "LIST",
                    },
                ])
            );
        case "hub.role.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "Hub.Role",
                        id: "LIST",
                    },
                    {
                        type: "Hub",
                        id: "LIST",
                    },
                    {
                        type: "Hub.Invite",
                        id: "LIST",
                    },
                ])
            );
        case "hub.role.state.changed":
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "Hub.Role",
                            id: "LIST",
                        },
                    ])
                );
            }

            return patchResourceState([
                {
                    type: "Hub.Role" as const,
                    id: message.object.id,
                },
            ]);
        case "hub.role.error":
        case "hub.role.error_reset":
            return patchErrorState([
                {
                    type: "Hub.Role" as const,
                    id: message.object.id,
                },
            ]);
        // VMs
        case "virtual-machine.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "VirtualMachine",
                        id: "LIST",
                    },
                ])
            );
        case "virtual-machine.updated":
        case "virtual-machine.reconfigured":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "VirtualMachine",
                        id: message.object.id,
                    },
                ])
            );
        case "virtual-machine.state.changed": {
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "VirtualMachine",
                            id: "LIST",
                        },
                    ])
                );
            }
            // Inline state update
            return patchResourceState([
                { type: "VirtualMachine" as const, id: message.object.id },
            ]);
        }
        case "virtual-machine.error":
        case "virtual-machine.error_reset":
            return patchErrorState([
                { type: "VirtualMachine" as const, id: message.object.id },
            ]);
        case "virtual-machine.ssh-key.created":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "VirtualMachineSshKey",
                        id: "LIST",
                    },
                ])
            );
        case "virtual-machine.ssh-key.updated":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "VirtualMachineSshKey",
                        id: message.object.id,
                    },
                ])
            );
        case "virtual-machine.ssh-key.state.changed": {
            if (message.object.state === "deleted") {
                dispatch(
                    api.util.invalidateTags([
                        {
                            type: "VirtualMachineSshKey",
                            id: "LIST",
                        },
                    ])
                );
            }
            // Inline state update
            return patchResourceState([
                {
                    type: "VirtualMachineSshKey" as const,
                    id: message.object.id,
                },
            ]);
        }
        case "virtual-machine.ssh-key.error":
        case "virtual-machine.ssh-key.error_reset":
            return patchErrorState([
                {
                    type: "VirtualMachineSshKey" as const,
                    id: message.object.id,
                },
            ]);
        case "virtual-machine.ips.modified":
            return dispatch(
                api.util.invalidateTags([
                    {
                        type: "VirtualMachine",
                        id: message.object.id,
                    },
                ])
            );
    }

    return [];
}
