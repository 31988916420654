import {
    StyledCell,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { LbUrlQueryTelem } from "./query";
import { ResponsesPieChart } from "./ResponsesPieChart";
import { HandlersPieChart } from "./HandlersPieChart";

export function UrlDestinationRow({
    destinationMetric,
}: {
    destinationMetric: LbUrlQueryTelem[number];
}) {
    return (
        <StyledTableRow key={destinationMetric.host}>
            <StyledCell className="max-w-[20rem]">
                <div className="max-w-[95%] truncate">
                    {destinationMetric.path}
                </div>
                <div className="text-sm">{destinationMetric.host}</div>
            </StyledCell>
            <StyledCell className="text-center">
                {destinationMetric.method}
            </StyledCell>
            <StyledCell>
                <div className="m-2 flex h-[4rem] w-[8rem] text-center">
                    <ResponsesPieChart
                        responses={destinationMetric.responses}
                    />
                </div>
            </StyledCell>

            <StyledCell>
                <div className="m-2 flex h-[4rem] w-[8rem] text-center">
                    <HandlersPieChart destinationMetric={destinationMetric} />
                </div>
            </StyledCell>

            <StyledCell className="text-center">
                {!!destinationMetric?.proxy_ms_avg && (
                    <div className="text-sm">
                        {`Proxy: ${Math.floor(
                            destinationMetric?.proxy_ms_avg
                        )} ms`}
                    </div>
                )}
                {!!destinationMetric?.cache_ms_avg && (
                    <div className="text-sm">
                        {`Cache: ${Math.floor(
                            destinationMetric?.cache_ms_avg
                        )} ms`}
                    </div>
                )}
                {!!destinationMetric?.forward_ms_avg && (
                    <div className="text-sm">
                        {`Forward: ${Math.floor(
                            destinationMetric?.forward_ms_avg
                        )} ms`}
                    </div>
                )}
                {!!destinationMetric?.redirect_ms_avg && (
                    <div className="text-sm">
                        {`Redirect: ${Math.floor(
                            destinationMetric?.redirect_ms_avg
                        )} ms`}
                    </div>
                )}
            </StyledCell>
        </StyledTableRow>
    );
}
