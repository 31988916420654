import { getLoadBalancerConfiguration } from "@cycleplatform/core/modules/environments/loadbalancer";
import { operations } from "@cycleplatform/core/modules/api/__generated";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { Transform } from "@fortawesome/fontawesome-svg-core";
import {
    faBox,
    faNetworkWired,
    faCircleExclamation,
    faGlobeAmericas,
    faCircleCheck,
    faTriangleExclamation,
} from "@fortawesome/pro-duotone-svg-icons";
import {
    faArrowLeft,
    faArrowRight,
    faBan,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { CSSProperties, useMemo } from "react";
import {
    ContainerPublicNetworkAnalysisStatus,
    NetworkAnalysis,
    NetworkAnalysisError,
    analyzeContainerNetworkStatus,
} from "~/modules/containers/util/network";
import {
    Container,
    LoadBalancerConfig,
    useGetContainerQuery,
    useGetLoadBalancerServiceQuery,
} from "~/services/cycle";
import { isCycleApiError } from "~/services/helpers";

type PublicNetworkDiagramProps = {
    container?: Container;
    formatTooltip?: (message: string) => string;
};

export function PublicNetworkDiagram({
    container,
    formatTooltip = (m) => m,
}: PublicNetworkDiagramProps) {
    const {
        data: lbInfo,
        isLoading: lbInfoLoading,
        error: lbInfoError,
    } = useGetLoadBalancerServiceQuery(
        {
            environmentId: container?.environment?.id || "",
        },
        { skip: !container }
    );

    const {
        data: loadbalancer,
        isLoading: lbLoading,
        error: lbContainerError,
    } = useGetContainerQuery(
        {
            containerId: lbInfo?.data.service?.container_id || "",
            meta: ["ips", "instances_count"],
            include: ["environments"],
        },
        { skip: !!lbInfoError || !lbInfo?.data }
    );

    const config = lbInfo?.data
        ? getLoadBalancerConfiguration(
              lbInfo.data as unknown as operations["getLoadBalancerService"]["responses"][200]["content"]["application/json"]["data"]
          )
        : undefined;

    const analysis = useMemo(
        () =>
            container
                ? analyzeContainerNetworkStatus(
                      container,
                      loadbalancer?.data,
                      // needed for type discrepancy until RTKQuery updates their codegen.
                      config as LoadBalancerConfig
                  )
                : null,
        [container, loadbalancer?.data, config]
    );
    const status = useMemo(
        () => (analysis ? adaptNetworkAnalysisToDiagram(analysis) : null),
        [analysis]
    );

    if (lbInfoError) {
        if (isCycleApiError(lbInfoError) && lbInfoError.status === 503) {
            // ignore 503s, those are sent on new envs.
        } else {
            throw lbInfoError;
        }
    }
    if (lbContainerError) {
        if (
            isCycleApiError(lbContainerError) &&
            lbContainerError.status === 503
        ) {
            // ignore 503s, those are sent on new envs.
        } else {
            throw lbContainerError;
        }
    }

    if (!container || lbLoading || lbInfoLoading || !analysis || !status) {
        return <div className={classNames(skeletonStyles, "h-12")} />;
    }

    return (
        <div className="mx-2 mt-2 flex items-center justify-between">
            {/* Internet */}
            <Tooltip message="Public Internet">
                <FontAwesomeIcon
                    icon={faGlobeAmericas}
                    className={classNames(
                        {
                            "text-cycle-blue": status.internet.status === "ok",
                            "text-warning":
                                status.internet.status === "warning",
                            "text-cycle-gray":
                                status.internet.status === "disabled",
                        },
                        "text-3xl"
                    )}
                />
            </Tooltip>

            {status.lb.hidden === false && (
                <>
                    {/* lb traffic */}
                    <Line
                        left={true}
                        right={true}
                        status={status.lbIngress.status}
                        message={formatTooltip(status.lbIngress.message)}
                    />
                    {/* lb */}
                    <Tooltip
                        disabled={!status.lb.message}
                        message={formatTooltip(status.lb.message)}
                    >
                        <span className="fa-layers fa-fw mr-4">
                            <FontAwesomeIcon
                                className={classNames(
                                    {
                                        "text-cycle-blue":
                                            status.lb.status === "ok",
                                        "text-error/50":
                                            status.lb.status === "error",
                                        "text-warning/50":
                                            status.lb.status === "warning",
                                        "text-cycle-gray/50 dark:text-cycle-gray-light/40":
                                            status.lb.status === "disabled",
                                    },
                                    "text-3xl"
                                )}
                                icon={faNetworkWired}
                            />
                            {status.lb.status === "error" ? (
                                <StatusErrorIcon
                                    transform={{ x: 20, y: -15 }}
                                />
                            ) : null}
                            {status.lb.status === "warning" ? (
                                <StatusWarningIcon
                                    transform={{ x: 20, y: -15 }}
                                />
                            ) : null}
                        </span>
                    </Tooltip>
                </>
            )}

            {/* container traffic */}
            {container.image.service !== "loadbalancer" && (
                <>
                    <Line
                        left={analysis.mode !== "disabled"}
                        right={analysis.mode === "public"}
                        status={status.containerIngress.status}
                        message={formatTooltip(status.containerIngress.message)}
                    />
                    {/* container */}
                    <Tooltip
                        disabled={!status.container.message}
                        message={formatTooltip(status.container.message)}
                    >
                        <span className="fa-layers fa-fw mr-2">
                            <FontAwesomeIcon
                                className={classNames(
                                    {
                                        "text-cycle-blue":
                                            status.container.status === "ok",
                                        "text-error/50":
                                            status.container.status === "error",
                                        "text-warning/50":
                                            status.container.status ===
                                            "warning",
                                        "text-cycle-gray/50 dark:text-cycle-gray-light/40":
                                            status.container.status ===
                                            "disabled",
                                    },
                                    "text-3xl"
                                )}
                                icon={faBox}
                            />
                            {status.container.status === "error" ? (
                                <StatusErrorIcon
                                    transform={{ x: 10, y: -15 }}
                                />
                            ) : null}
                            {status.container.status === "warning" ? (
                                <StatusWarningIcon
                                    transform={{ x: 10, y: -15 }}
                                />
                            ) : null}
                        </span>
                    </Tooltip>
                </>
            )}
        </div>
    );
}

function adaptNetworkAnalysisToDiagram(analysis: NetworkAnalysis) {
    const errors = getErrorsByType(analysis.errors);
    const isDisabled = analysis.mode === "disabled";

    const overPortsString = analysis.exposedPorts
        .map((p) => `${p.lbIngress}:${p.containerIngress}`)
        .join(", ");

    return {
        internet: {
            status: "ok",
        },
        lbIngress: {
            hidden: analysis.mode === "egress", // hide load balancer if egress (traffic doesn't go through it.)
            status: getHighestLevelStatus(errors["lb-ingress"]),
            message:
                errors["lb-ingress"].map((e) => e.details).join("\n") ||
                "Load balancer ingress configured correctly.",
        },
        lb: {
            hidden: analysis.mode === "egress", // hide load balancer if egress (traffic doesn't go through it.)
            status: getHighestLevelStatus(errors["lb"]),
            message:
                errors["lb"].map((e) => e.details).join("\n") ||
                "Load balancer configured correctly.",
        },
        containerIngress: {
            status: isDisabled
                ? "disabled"
                : getHighestLevelStatus(errors["container-ingress"]),
            message: isDisabled
                ? "Container public network is set to disabled."
                : errors["container-ingress"]
                      .map((e) => e.details)
                      .join("\n") ||
                  `Container <-> ${
                      analysis.mode === "egress" ? "internet" : "load balancer"
                  } connection configured for ${
                      analysis.mode === "egress"
                          ? "outbound (egress)"
                          : "bi-directional"
                  } traffic${
                      overPortsString ? ` over ports ${overPortsString}` : ""
                  }.`,
        },
        container: {
            status: isDisabled
                ? "disabled"
                : getHighestLevelStatus(errors["container"]),
            message: isDisabled
                ? "Container public network is set to disabled."
                : errors["container"].map((e) => e.details).join("\n") ||
                  "Container configured correctly.",
        },
    };
}

function getErrorsByType(
    errors: NetworkAnalysis["errors"]
): Record<string, NetworkAnalysisError[]> {
    return errors.reduce(
        (acc, cur) => {
            const prefix = cur.code.split(".")[0];
            if (!acc[prefix]) {
                acc[prefix] = [];
            }

            acc[prefix].push(cur);

            return acc;
        },
        {
            "lb-ingress": [],
            lb: [],
            "container-ingress": [],
            container: [],
        } as Record<string, NetworkAnalysisError[]>
    );
}

function getHighestLevelStatus(errors: NetworkAnalysis["errors"]) {
    const ord: ContainerPublicNetworkAnalysisStatus[] = [
        "error",
        "warning",
        "disabled",
        "ok",
    ];
    return errors.reduce((acc, cur) => {
        if (ord.indexOf(acc) > ord.indexOf(cur.type)) {
            acc = cur.type;
        }
        return acc;
    }, "ok" as ContainerPublicNetworkAnalysisStatus);
}

function Line({
    left,
    right,
    status,
    message,
}: {
    left: boolean;
    right: boolean;
    status: ContainerPublicNetworkAnalysisStatus;
    message: string | null;
}) {
    return (
        <div className="relative mx-4 flex w-full items-center">
            {left ? (
                <FontAwesomeIcon
                    className={classNames(
                        {
                            "text-cycle-black dark:text-cycle-gray-light/80":
                                status === "ok",
                            "text-error/50": status === "error",
                            "text-warning/50": status === "warning",
                            "text-cycle-gray/50 dark:text-cycle-gray-light/40":
                                status === "disabled",
                        },
                        "text-3xl"
                    )}
                    icon={faArrowLeft}
                />
            ) : null}

            <Tooltip
                message={message}
                className="absolute left-0 right-0 flex w-full justify-center"
            >
                {status === "ok" ? <StatusOkIcon /> : null}
                {status === "error" ? <StatusErrorIcon /> : null}
                {status === "warning" ? <StatusWarningIcon /> : null}
                {status === "disabled" ? <StatusDisabledIcon /> : null}
            </Tooltip>

            <div
                className={classNames(
                    {
                        "border-t-cycle-black dark:border-t-cycle-gray-light/80":
                            status === "ok",
                        "border-t-error/50": status === "error",
                        "border-t-warning/50": status === "warning",
                        "border-t-cycle-gray/50 dark:border-t-cycle-gray-light/40":
                            status === "disabled",
                    },
                    " mx-4 inline-block w-full border-t-4 border-dashed"
                )}
            />

            {right ? (
                <FontAwesomeIcon
                    className={classNames(
                        {
                            "text-cycle-black dark:text-cycle-gray-light/80":
                                status === "ok",
                            "text-error/50": status === "error",
                            "text-warning/50": status === "warning",
                            "text-cycle-gray/50 dark:text-cycle-gray-light/40":
                                status === "disabled",
                        },
                        "text-3xl"
                    )}
                    icon={faArrowRight}
                />
            ) : null}
        </div>
    );
}

function StatusOkIcon({ transform }: { transform?: Transform }) {
    return (
        <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-cycle-white dark:text-cycle-black text-xl"
            transform={transform}
            style={
                {
                    "--fa-secondary-opacity": "1",
                    "--fa-secondary-color": "rgb(34 211 104)",
                } as CSSProperties
            }
        />
    );
}

function StatusDisabledIcon({ transform }: { transform?: Transform }) {
    return (
        <FontAwesomeIcon
            icon={faBan}
            className="text-cycle-gray-accent dark:text-cycle-gray-light/60 text-xl"
            transform={transform}
        />
    );
}

function StatusWarningIcon({ transform }: { transform?: Transform }) {
    return (
        <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-cycle-white text-xl"
            transform={transform}
            style={
                {
                    "--fa-secondary-opacity": "1",
                    "--fa-secondary-color": "rgb(255 130 42)",
                } as CSSProperties
            }
        />
    );
}

function StatusErrorIcon({ transform }: { transform?: Transform }) {
    return (
        <FontAwesomeIcon
            icon={faCircleExclamation}
            className="text-cycle-white text-xl"
            transform={transform}
            style={
                {
                    "--fa-secondary-opacity": "1",
                    "--fa-secondary-color": "rgb(211 34 53)",
                } as CSSProperties
            }
            fade
        />
    );
}
